import { useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import PasswordStrengthBar from 'react-password-strength-bar';

import { signupWithEmail, signupWithEmailConfirm, UserContext } from 'UserContext.tsx';


export default function SignupEmail(props) {

  const [snackOpen, setSnackOpen] = useState(false);
  const [email, setEmail] = useState("")
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [confirmCode, setConfirmCode] = useState("")
  const [message, setMessage] = useState("")
  const [signupState, setSignupState] = useState("init"); // states can be init, confirm
  const uc = useContext(UserContext);

  const handleSignup1 = async (data) => {
    if (await signupWithEmail(uc, email, password1)) {
      setSignupState("confirm")
    } else {
      setMessage("Oops, username/email taken");
      setSnackOpen(true);
    }    
  };

  const handleSignup2 = async (data) => {
    if (await signupWithEmailConfirm(uc, email, password1, confirmCode)) {
      props.signedInUpUpdateHandler();
    } else {
      setMessage("Oops, confirm code does not match");
      setSnackOpen(true);
    }    
  };

  return (
      <Grid container direction="column" justifyContent="flex-start" alignItems="left" rowGap={2}>
        <Grid item>
          <TextField
            required
            id="email"
            label="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            style = {{width: 300}} 
            disabled={signupState == "confirm"}
            />
        </Grid>
        <Grid item>
          <TextField
            required
            id="password"
            label="Password"
            type="password"
            value={password1}
            onChange={e => setPassword1(e.target.value)}
            style = {{width: 300}} 
            disabled={signupState == "confirm"}
            />
        </Grid>
        <Grid item sx={{width: 300, marginTop: -2}}>
        { password1 && 
          <PasswordStrengthBar password={password1} barColors={['#ddd', '#ef4836', '#f6b44d', '#2b90ef', '#25c281']} minLength={8}/>
        }
        </Grid>
        <Grid item>
          <TextField
            required
            id="password"
            label="Confirm Password"
            type="password"
            value={password2}
            onChange={e => setPassword2(e.target.value)}
            style = {{width: 300}} 
            disabled={signupState == "confirm"}
            />
        </Grid>
        { signupState == "init" &&
          <Grid item>
            <Button autoFocus onClick={handleSignup1}
              disabled={!(signupState==="init" && email && password1 && password2 && (password1 === password2) && password1.length >= 8)}>
              Sign Up
            </Button>
          </Grid>
        }
        { signupState == "confirm" &&
          <>
            <Grid item>
              <TextField
                required
                id="code"
                label="Please enter code sent to your Email"
                value={confirmCode}
                onChange={e => setConfirmCode(e.target.value)}
                style = {{width: 300}} 
                />
            </Grid>
            <Grid item>
              <Button autoFocus onClick={handleSignup2} disabled={!(signupState==="confirm" && confirmCode)}>
                Continue
              </Button>
            </Grid>
          </>
        }
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          open={snackOpen}
          autoHideDuration={4000}
          onClose={() => {setSnackOpen(false)}}
          message={message}
        />
      </Grid>    
  );
}