import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { PrivacyPolicyContent } from 'components/PrivacyPolicyContent';

export default function PrivacyPolicy() {

  return (
    <>
      <Grid container direction="column">
        <Grid item marginBottom={3} marginLeft={2}>
          <Typography variant="h4">
            Privacy Policy
          </Typography>
        </Grid>
        <Grid item margin={3}>
          <PrivacyPolicyContent></PrivacyPolicyContent>
        </Grid>
      </Grid>
    </>);
}