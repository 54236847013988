import { Typography, Button, Checkbox, FormGroup, FormControlLabel, Snackbar } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useEffect, useState }  from 'react';

import TextField from '@mui/material/TextField';

import { axApiContext, UserContext } from 'UserContext';

export default function Account() {

  const [snackOpen, setSnackOpen] = useState(false);
  const [message, setMessage] = useState("")
  const [email, setEmail] = useState("")
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [origSharePublicImage, setOrigSharePublicImage] = useState(false);
  const [origSharePublicPrompt, setOrigSharePublicPrompt] = useState(false);
  const [origSharePublicMetadata, setOrigSharePublicMetadata] = useState(false);
  const [sharePublicImage, setSharePublicImage] = useState(false);
  const [sharePublicPrompt, setSharePublicPrompt] = useState(false);
  const [sharePublicMetadata, setSharePublicMetadata] = useState(false);
  const uc = useContext(UserContext);

  useEffect(() => {
    setEmail(uc?.context?.email)
    getShareSettings();
  }, []);

  const handleChangePassword = async () => {
    axApiContext(uc, 'PUT', '/user/password', {"password": password1});
    setMessage("Password updated")
    setSnackOpen(true);

  }

  const handleChangeEmail = () => {
    // TODO:
  }

  const handleSave = async () => {
    const r = await axApiContext(uc, 'PUT', '/user/settings', {
      "share_public_image": sharePublicImage,
      "share_public_prompt": sharePublicPrompt,
      "share_public_metadata": sharePublicMetadata
    });
    setOrigSharePublicImage(sharePublicImage);
    setOrigSharePublicPrompt(sharePublicPrompt);
    setOrigSharePublicMetadata(sharePublicMetadata);

    setMessage("Share settings saved")
    setSnackOpen(true);
  }

  const getShareSettings = async () => {
    const r = await axApiContext(uc, 'GET', '/user/settings');
    setSharePublicImage(r.share_public_image);
    setSharePublicPrompt(r.share_public_prompt);
    setSharePublicMetadata(r.share_public_metadata);
    setOrigSharePublicImage(r.share_public_image);
    setOrigSharePublicPrompt(r.share_public_prompt);
    setOrigSharePublicMetadata(r.share_public_metadata);
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item marginBottom={3} marginLeft={2}>
          <Typography variant="h4">
            Account Settings
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={2} margin={1}>
            <Grid item>
              <TextField
                  id="email"
                  label="Email"
                  inputProps={
                    { readOnly: true, }
                  }
                  style = {{width: 260}} 
                  value={email}
                  disabled={true}
              />
            </Grid>
            <Grid item>
              <Button autoFocus onClick={handleChangeEmail} disabled={true} variant="outlined">
                Change Email
              </Button>
            </Grid>
            <Grid item>
              <TextField
                id="password1"
                label="Password"
                type="password"
                style = {{width: 260}} 
                value={password1}
                onChange={e => setPassword1(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                id="password2"
                label="Confirm Password"
                type="password"
                style = {{width: 260}} 
                value={password2}
                onChange={e => setPassword2(e.target.value)}
              />
            </Grid>
            <Grid item>
              <Button 
                autoFocus 
                onClick={handleChangePassword} 
                disabled={password1 !== "" && password1 != password2} 
                variant="outlined"
              >
                Change Password
              </Button>
            </Grid>
            <Grid item paddingTop={5}>
              <Typography>
                Default Share Settings
              </Typography>
            </Grid>
            <Grid item>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={sharePublicImage} onChange={e => setSharePublicImage(e.target.checked)}/>} label="Share Images" />
                <FormControlLabel control={<Checkbox checked={sharePublicPrompt} onChange={e => setSharePublicPrompt(e.target.checked)}/>} label="Share Prompts" />
                <FormControlLabel control={<Checkbox checked={sharePublicMetadata} onChange={e => setSharePublicMetadata(e.target.checked)}/>} label="Share Image Metadata" />
              </FormGroup>
            </Grid>
            <Grid item>
              <Button onClick={handleSave} disabled={
                  sharePublicImage==origSharePublicImage &&
                  sharePublicPrompt==origSharePublicPrompt &&
                  sharePublicMetadata==origSharePublicMetadata
                }
                variant="outlined">
                Save Share Settings
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            open={snackOpen}
            autoHideDuration={4000}
            onClose={() => {setSnackOpen(false)}}
            message={message}
          />
      </Grid>
    </>
  ); 
}
