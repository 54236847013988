import { useState, useEffect } from "react";
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import StateCombo from "components/ElementState";


export default function CheckoutAddress({setIsValid, address, setAddress}) {

  useEffect(() => {
    setIsValid(
      address.firstName.length > 0 &&
      address.lastName.length > 0 &&
      address.city.length > 0 &&
      address.state.length > 0 &&
      address.postalCode.length > 0);
  }, [address]);

  return (
    <form>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="First Name"
            name="firstName"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => setAddress((p)=>({...p, firstName: e.target.value}))}
            value={address?.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Last Name"
            name="lastName"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => setAddress((p)=>({...p, lastName: e.target.value}))}
            value={address?.lastName}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            label="Street Address 1"
            name="address1"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => setAddress((p)=>({...p, address1: e.target.value}))}
            value={address?.address1}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            label="Street Address 2 (optional)"
            name="line2"
            variant="outlined"
            fullWidth
            onChange={(e) => setAddress((p)=>({...p, address2: e.target.value}))}
            value={address?.address2}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="City"
            name="city"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => setAddress((p)=>({...p, city: e.target.value}))}
            value={address?.city}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="State"
            name="state"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => setAddress((p)=>({...p, state: e.target.value}))}
            value={address?.state}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Postal Code"
            name="postalCode"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => setAddress((p)=>({...p, postalCode: e.target.value}))}
            value={address?.postalCode}
          />
        </Grid>
      </Grid>
    </form>
  );
}
