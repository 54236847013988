import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import { DialogGeneric } from './DialogGeneric';
import AppInfo from 'AppInfo.json';

const body1 = `Our mission is to provide you with the tools and resources to go on an artistic adventure and bring your visions to life.  Whether you are a seasoned artist or a beginner, we believe that everyone has the potential to create something truly unique and special.

We specialize in using the latest technology to bring custom art to you in a way that is both accessible and affordable.  Our cutting-edge platform allows you to create and customize your own images and designs to produce one-of-a-kind works of art that are truly your own.

Whether you're looking to create a piece for your home, office, or as a gift for a loved one, we're here to help.  Our platform is easy to use, and we offer a wide range of options to suit your needs.

Thank you for choosing Panoramix. We look forward to helping you bring your artistic vision to life and seeing where your journey takes you!

Julien Croy
Founder and President`


// eslint-disable-next-line
export const DialogAboutUs = forwardRef((props, ref) => {
  const genericEl = useRef(null);

  useImperativeHandle(ref, () => ({
    open() {
      genericEl.current.open();
    }
  }));

  return (
    <DialogGeneric ref={genericEl} title="About Us">
      <Grid container direction="column" alignItems="center" rowGap={3}>
        <Grid item>
          <Typography style={{whiteSpace: 'pre-line'}} align="left">
            {body1}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" component="h2" align="center">
            Panoramix, LLC<br/>
            40 Cranston Avenue<br/>
            Newport, RI 02840<br/>
            <br/>
            <br/>
            Version {AppInfo.version}-{AppInfo.buildNum}<br/>
          </Typography>
        </Grid>
      </Grid>
    </DialogGeneric>
  );
});

