import { Button, Card, CardActions, CardContent, CardMedia } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useEffect, useState }  from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { axApi } from 'utils/api.tsx';


export default function Products() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  useEffect(() => {

    axApi("GET", "./products", "", {grouped: "1"}).then(data => setProducts(data));
  
  }, []);

  const handleBuy = (p) => {
    ReactGA.event({category: "click", action: "products-buy"})
    navigate("/product", {state: {groupKey: p.group_key}})
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item marginBottom={3} marginLeft={2}>
          <Typography variant="h4">
            Products
          </Typography>
        </Grid>
        <Grid container spacing={2} margin={1}>
          {products.map((product) => (
            <Grid item key={product.group_key} xs={12} sm={4} md={4}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    // 16:9
                    //https://source.unsplash.com/random
                    //                    alt="random"
                  }}
                  image={product.showcase_image_url}
                  style={{backgroundColor: 'white'}}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {product.group_key}
                  </Typography>
                  <Typography>
                    {`Starting at $${product.price} for ${product.name_hr}s.`}
                  </Typography>
                  <Typography>
                    {product.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={() => handleBuy(product)}>buy</Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>);
}
