import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Grid, IconButton, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import PasswordStrengthBar from 'react-password-strength-bar';

import { DialogGeneric } from './DialogGeneric';
import { axApi, Urls } from 'utils/api.tsx';
import { UserContext } from 'UserContext.tsx';


export const DialogForgotPassword = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [email, setEmail] = useState("")
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [confirmCode, setConfirmCode] = useState("")
  const [message, setMessage] = useState("")
  const [forgotState, setForgotState] = useState(1); // states can be 1:init, 2:confirm, 3:change
  const uc = useContext(UserContext);
  const genericEl = useRef(null);

  useImperativeHandle(ref, () => ({
    open() {
      genericEl.current.open();
    },
    close() {
      genericEl.current.close();
    }
  }));

  const handleClose = () => {
    genericEl.current.close();
    if (props?.closeCb) {
      props.closeCb();
    }
  };

  const handleSendCode = () => {
    setForgotState(2);

    // send code
    try {
      const data = {email}
      axApi('POST', Urls.userForgotPassword, data).then(r => {});
    } catch (e) {
      setMessage("Could not send the code, please try again");
      setSnackOpen(true);
    }
  }

  const updateConfirmCode = (v) => {
    setConfirmCode(v);
    if (v.length == 6) {
      setForgotState(3);
    }
  }

  const handleUpdatePassword = () => {
    setMessage("Password updated");
    setSnackOpen(true);
    const timer = setTimeout(() => {
      setOpen(false);
    }, 1000);

    // change password supplying password and code
    try {
      const data = {email, password: password1, confirm_code: confirmCode}
      axApi('PUT', Urls.userForgotPassword, data).then(r => {
        props.onClose()
      });
    } catch (e) {
      setMessage("Error setting the password, please try again");
      setSnackOpen(true);
    }
  }

  return (
    <DialogGeneric ref={genericEl} title="Forgot Password">
      <Grid container direction="column" justifyContent="flex-start" alignItems="left" rowGap={2}>
        <Grid item>
          <Typography gutterBottom>
          Click the SEND CODE button and we'll send a code to your email.
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            required
            id="email"
            label="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            style = {{width: 300}} 
          />
        </Grid>
        <Grid item>
          <Button 
            autoFocus 
            onClick={handleSendCode}
            disabled={forgotState != 1 || !email.length}>
            Send Code
          </Button>
        </Grid>
        { forgotState >= 2 &&
          <Grid item>
            <TextField
              required
              id="code"
              label="Please enter code sent to your Email"
              value={confirmCode}
              onChange={e => updateConfirmCode(e.target.value)}
              style = {{width: 300}} 
              disabled={forgotState != 2}
              />
          </Grid>
        }
        { forgotState >= 3 &&
          <>
            <Grid item>
              <TextField
                required
                id="password1"
                label="Password"
                type="password"
                value={password1}
                onChange={e => setPassword1(e.target.value)}
                style = {{width: 300}} 
                disabled={forgotState != 3}
                />
            </Grid>
            <Grid item sx={{width: 300, marginTop: -2}}>
              { password1 && 
                <PasswordStrengthBar password={password1} barColors={['#ddd', '#ef4836', '#f6b44d', '#2b90ef', '#25c281']} minLength={8}/>
              }
            </Grid>
            <Grid item>
              <TextField
                required
                id="password2"
                label="Confirm Password"
                type="password"
                value={password2}
                onChange={e => setPassword2(e.target.value)}
                style = {{width: 300}} 
                disabled={forgotState != 3}
                />
            </Grid>
            <Grid item>
              <Button
                autoFocus
                onClick={handleUpdatePassword}
                disabled={!(forgotState == 3 && password1.length >= 8 && password1 == password2)}>
                Set Password
              </Button>
            </Grid>
          </>
        }
      </Grid>    
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={snackOpen}
        autoHideDuration={4000}
        onClose={() => {setSnackOpen(false)}}
        message={message}
      />
    </DialogGeneric>
  );
});
