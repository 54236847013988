import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import { DialogGeneric } from './DialogGeneric';


const body1 = `How our site works

Our website makes it fun and easy to create wild works of art from simple text ideas. It's now possible for you to make funky images from ideas or turn rough sketches or doodles into reality with our site. 

1. Signup for an account by clicking the signup button. You can create your own account or sign in utilizing your Google or Facebook accounts. If you decide to create an account, then enter in your email address and create a secure password. We will then send an email to your account to verify your email address. 

2. If you already have an account go ahead and login by clicking on the sign in button. 

3. Once logged in you should see the homepage of Panoramix. Click the + button to generate an image. Note if you have never created an image on our site before a window should appear and prompt you to generate one. 

4. In the prompt window that appears, describe your idea!  

5. There are drop down menus below where you enter in your idea that can be used to add modifiers to the prompt if you need ideas.  

6. I wanted to create a photo of a bunny with wings so I typed in the following: "a 4k photo of a bunny with (birdlike wings), brighter, sharper”. This results in the image below being produced.  The prompt includes a 4k photo to set the medium of the output to a sharp image. If you want an image in the style of a painting, you could put in oil painting.  

7. You can use parentheses () around keywords to give them more weight in a search query, while square brackets [] are used to decrease the weight of the words inside.

8. If you are dissatisfied with the generated image, you can try changing the prompt to get a better output. Experimenting with prompts is an integral part of the journey, and there are several tutorials and help pages available to guide you along this journey in creating an effective prompt.  

9. Our website offers another great feature! You can use existing images that you previously created on our site or uploaded and use them to influence the output of new media.   

10. For example let's say you liked the style of the bunny above but don’t want to change the image drastically? With  this feature you can create images that follow the original one in design and control how much it changes. 

11. Select the image above on our site, click the white background plus button, enter in the prompt, you can keep it the same or modify it, select your change percentage.                                                         

12. Experiment with the change percentage value to achieve varied outputs. Adjusting the slider towards the left will result in subtle changes, while moving it to the extreme right will generate a vastly different output. 

13. Keeping the prompt the same and modifying the change percentage is a great way to produce similar results with varying degrees of differences. 

14. Doing so resulted in a bunny with more detailed wings but did not change the image that drastically.

15. Notice how the second image is very similar to the initial image. This is due to a low change percent being set.

16. Using the image to image feature, you can turn the above image into another medium. If you wanted to turn it into a painting for example, replace 4k photo with oil painting results in the below image: 

17. Generating a batch of images using the text-to-image feature is a method for creating remarkable works of art. Once you have found an image you like, it can be further refined by using the image-to-image process. This iterative approach allows you to fine-tune the output until you find the perfect image for you! 

18. Once you find an image that catches your eye you can then preview it on a variety of products! 

19. Once you have an image that you love, navigate to the gallery and hover your mouse over the top left of the image. Click the checkbox that appears.

20. Now you have an image selected, click on the shopping cart icon in the menu.

21. Then select the product you are interested in and a preview of the product with your image on it will appear. 

22. You can adjust how your image will print on the product by using the crop tool to align the image.

23. Once you have an image’s appearance on the product squared away you can then add the product to your cart.  
`


// eslint-disable-next-line
export const DialogHowItWorks = forwardRef((props, ref) => {
  const genericEl = useRef(null);

  useImperativeHandle(ref, () => ({
    open() {
      genericEl.current.open();
    }
  }));

  return (
    <DialogGeneric ref={genericEl} title="How It Works">
      <Grid container direction="column" alignItems="center" rowGap={3}>
        <Grid item>
          <Typography style={{whiteSpace: 'pre-line'}} align="left">
            {body1}
          </Typography>
        </Grid>
      </Grid>
    </DialogGeneric>
  );
});

