import { Box } from '@mui/material';
import { forwardRef, useContext, useImperativeHandle, useRef, useState }  from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import FileUpload from 'react-material-file-upload';
import Snackbar from '@mui/material/Snackbar';

import { DialogGeneric } from './DialogGeneric';
import { axApiContext, UserContext } from 'UserContext';

// eslint-disable-next-line
export default DialogUpload = forwardRef((props, ref) => {
  const uc = useContext(UserContext);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const genericEl = useRef(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [message, setMessage] = useState("")

  useImperativeHandle(ref, () => ({
    open() {
      genericEl.current.open();
    }
  }));

  const handleClose = () => {
    genericEl.current.close();
    if (props?.closeCb) {
      props.closeCb();
    }
  };

  const handleChange = async (files) => {
    setLoading(true);
    try {
      await axApiContext(uc, 'POST', '/uimage', files[0], null, true);
      setLoading(false);
      handleClose();
    } catch (e) {
      setLoading(false);
      setMessage("Sorry, cannot upload image for some reason")
      setSnackOpen(true);
    }
  }

  return (
    <DialogGeneric ref={genericEl} title="Upload Image">
      <FileUpload value={files} onChange={handleChange} />
      {loading &&
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      }
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={() => {setSnackOpen(false)}}
        message={message}
      />
    </DialogGeneric>
  );
});

function DialogUpload(props) {
}