import { useContext, useEffect, useState }  from 'react';
import { useNavigate } from "react-router-dom";
import { Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { axApiContext, UserContext } from 'UserContext';

export default function OrderHistory() {
  const [items, setItems] = useState([]);
  let navigate = useNavigate();
  const uc = useContext(UserContext);

  useEffect(() => {
    updateItems();
  }, []);

  const updateItems = async () => {
    const r = await axApiContext(uc, 'GET', '/carts');
    setItems(r);
  }

  const formatDate = (sdt) => {
    const dt = new Date(Date.parse(sdt));
    return dt.toLocaleString();
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item marginBottom={3} marginLeft={2}>
          <Typography variant="h4">
            Order History
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TableContainer sx={{bgcolor: "background.default"}}>
            <Table aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>
                      Date
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      Order Number
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      Amount
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      Order Details
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5}>
                    <Divider></Divider>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items !== null && items.length > 0 &&
                  items.map((item) => ( item.status != "building" &&
                  <TableRow key={item.uuid}>
                    <TableCell>
                      <Typography>
                        {formatDate(item.date)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {item.orderNum}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {item.status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        ${item.total}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Link href={`/order?uuid=${item.uuid}`}>
                        <Typography>
                          Details
                        </Typography>
                      </Link>
                    </TableCell>
                </TableRow>))
                }
                {(items === null || items.length == 0) &&
                <TableRow key={1}>
                  <TableCell>
                    <Typography>
                      Empty order history
                    </Typography>
                  </TableCell>
                </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>);
}
