import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReactGA from 'react-ga4';

import Grid from '@mui/material/Unstable_Grid2';

import { axApiContext, UserContext } from 'UserContext';
import { ProductImagePreview } from 'utils/productImagePreview';
import { CustomThemeContext, darkPalette, lightPalette } from 'theme';

function ccyFormat(num) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(num);
}

const label = { inputProps: { 'aria-label': 'Selected' } };

export default function Cart() {
  const [items, setItems] = useState([])
  const [subTotal, setSubtotal] = useState("0.00")
  const [subTotalAfterDiscount, setSubtotalAfterDiscount] = useState("0.00")
  const [discount, setDiscount] = useState("0.00")
  const navigate = useNavigate();
  const uc = useContext(UserContext);
  const largeWidth = useMediaQuery('(min-width:600px)');
  const itemWidth = largeWidth ? 350 : 120;
  const [promoCode, setPromoCode] = useState("")
  const { currentTheme } = useContext(CustomThemeContext);
  const [palette, setPalette] = useState(currentTheme == "dark" ? darkPalette : lightPalette);

  useEffect(() => {
    updateItems();
  }, []);

  const updateItems = async () => {
    const r = await axApiContext(uc, 'GET', '/cart/details');
    setItems(r.items);
    setSubtotal(r.subtotal);
    setDiscount(r.discount)
    setSubtotalAfterDiscount(r.subtotalAfterDiscount);
    setPromoCode(r.promoCode);
  }

  const updateQuantity = async (value, item) => {
    setItems((prev) => prev.map((_item) => {
      if (_item.uuid===item.uuid) {
        return {..._item, quantity: value}
      } else {
        return _item;
      }
    }));
    if (value==0) {
      await axApiContext(uc, 'DELETE', '/cart/item/' + items[value].uuid);
    } else {
      await axApiContext(uc, 'PUT', '/cart/item', {
        ...item,
        quantity: value,
        product_uuid: item.product.uuid, 
        image_uuids: [item.images[0]?.uuid]
      });
    }
    updateItems();
  }

  const handleUpdatePromoCode = async () => {
    await axApiContext(uc, 'PUT', '/cart/promo', {promoCode});
    updateItems();
  }

  const handleCheckout = () => {
    ReactGA.event({category: "click", action: "cart-checkout"})
    navigate("/checkout");
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item marginBottom={3} marginLeft={2}>
          <Typography variant="h4">
            Shopping Cart
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} sx={{maxWidth: 1000}}>
          <TableContainer sx={{bgcolor: "background.default"}}>
            <Table aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right" sx={{paddingBottom: 0, marginBottom: 0}}>
                  {items !== null && items.length > 0 &&
                    <Typography variant="h6">Price</Typography>
                  }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} sx={{paddingTop: 0, marginTop: 0}}>
                    <Divider style={{borderBottom: `2px solid ${palette.divider}`}}></Divider>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items !== null && items.length > 0 &&
                  items.map((item) => (
                    <>
                  <TableRow key={item.uuid}>
                    <TableCell>
                      <ProductImagePreview 
                        galleryImageUrl={item?.images[0]?.url}
                        galleryImageSize={{w: item?.images[0]?.metadata?.width, h: item?.images[0]?.metadata?.height}}
                        previewRect={{x: item.product.preview_pos_px_x, y: item.product.preview_pos_px_y, w: item.product.preview_pos_px_w, h: item.product.preview_pos_px_h}}
                        productImageUrl={item.product.preview_image_url}
                        cropRect={item.metadata.cropRect}
                        displayWidth={itemWidth}
                      />
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column" alignContent="center">
                        <Grid item>
                          <Typography>
                            {item.product.name}
                          </Typography>
                        </Grid>
                        {largeWidth &&
                          <Grid item>
                            <Typography>
                              {item.product.description}
                            </Typography>
                          </Grid>
                        }
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Qty</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={item.quantity}
                          defaultValue={1}
                          label="Qty"
                          onChange={(e) => updateQuantity(e.target.value, item)}
                        >
                          <MenuItem value={0}>Remove</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={6}>6</MenuItem>
                          <MenuItem value={7}>7</MenuItem>
                          <MenuItem value={8}>8</MenuItem>
                          <MenuItem value={9}>9</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                        </Select>
                      </FormControl>
                      <Button onClick={e => updateQuantity(0, item)} >
                          remove
                      </Button>
                    </TableCell>

                    <TableCell align="right">
                      <Typography variant="h6">{ccyFormat(item.product.price * item.quantity)}</Typography>
                    </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Divider style={{borderBottom: `2px solid ${palette.divider}`}}></Divider>
                      </TableCell>
                    </TableRow>
                    </>                
                ))}
                {items !== null && items.length == 0 &&
                  <TableRow key="0">
                    <TableCell>
                      <Typography>Your cart is empty</Typography>
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
            <Grid container justifyContent="flex-end" sx={{p: 2}} alignItems="center" spacing={2}>
              <Grid>
                <TextField
                  id="promoCode"
                  label="Enter Promo Code"
                  style = {{width: 260}} 
                  value={promoCode}
                  onChange={e => setPromoCode(e.target.value)}
                  onBlur={handleUpdatePromoCode}
                  />
              </Grid>
              <Grid>
                <Button variant="contained" disabled={!(items !== null & items.length>0)} type="submit" onClick={handleUpdatePromoCode}>Apply</Button>
              </Grid>
            </Grid>
            { discount !== "0.00" && 
            <>
              <Grid container justifyContent="flex-end" sx={{p: 2}}>
                <Typography variant="h5">Subtotal before discount: {ccyFormat(subTotal)}</Typography>
              </Grid>
              <Grid container justifyContent="flex-end" sx={{p: 2}}>
                <Typography variant="h5">Discount: {ccyFormat(discount)}</Typography>
              </Grid>
            </>
            }
            <Grid container justifyContent="flex-end" sx={{p: 2}}>
              <Typography variant="h5">Subtotal: {ccyFormat(subTotalAfterDiscount)}</Typography>
            </Grid>
            <Grid container justifyContent="flex-end" sx={{p: 2}}>
              <Button variant="contained" disabled={!(items !== null & items.length>0)} type="submit" onClick={handleCheckout}>Proceed to check out</Button>
            </Grid>
          </TableContainer>
        </Grid>
      </Grid>
  </>);
}
