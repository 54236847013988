import * as React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, useImperativeHandle } from 'react';


const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

// eslint-disable-next-line
export const DialogGeneric = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    open() {
      handleOpen();
    },
    close() {
      handleClose();
    }
  }));

  const [openState, setOpenState] = React.useState(false);

  const handleOpen = () => {
    setOpenState(true);
  };

  const handleClose = (event, reason) => {
    setOpenState(false);
    if (props.handleClose) {
      props.handleClose()
    }
  };

  return (
    <Dialog
      open={openState}
      onClose={handleClose}
      aria-labelledby="generic-dialog-title"
      aria-describedby="generic-dialog-description"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {props.title}
      </BootstrapDialogTitle>
      <DialogContent>
        {props.children}
      </DialogContent>
    </Dialog>
  );
});

