import { Box, Button, LinearProgress, Menu, MenuItem, Slider, Snackbar, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReactGA from 'react-ga4';

import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState }  from 'react';
import { axPub, Urls } from 'utils/api';
import { axApiContext, axApiContextOptional, UserContext } from 'UserContext';
import AppInfo from 'AppInfo.json';

ReactGA.initialize(AppInfo.gtag);


const PromptMenu = ({ title, texts, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedText, setSelectedText] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (text) => {
    setSelectedText(text);
    setAnchorEl(null);
    onSelect(text)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button onClick={handleClick}>{title}</Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {texts.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuItemClick(item.text)}
            style={{whiteSpace: 'normal'}} 
          >
            { item.image &&
              <Box
                component="img"
                sx={{
                  height: { xs: 100, md: 100 },
                }}
                src={item.image}
              />
            }
            {item.text}            
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};


// ImageInstance returns jsx for a single image 
const ImageInstance = forwardRef((props, ref) => {

  const [selected, setSelected] = useState(false)

  useImperativeHandle(ref, () => ({
    getState() {
      return selected;
    },
    setState(s) {
      setSelected(s);
    }
  }));

  const btnPos = {
    top: "-16%",
    left: "35%"
  }

  const handleSelect = () => {
    setSelected(prevState => (!prevState));
  }
  
  if (props.showImages) {
    return (
      <Box
        sx={{
          height: { xs: 270, md: 190 },
          width: { xs: 270, md: 190 },
        }}>
        <Box
          component="img"
          sx={{
            height: { xs: 270, md: 190 },
            width: { xs: 270, md: 190 },
          }}
          src={props.imageUrl}
        />
        <Button
          position="relative"
          style={btnPos}
          variant="contained"
          size="small"
          sx={{padding: 0, margin: 0}}
          onClick={handleSelect}
        >
          {selected? "saved" : "save"}
        </Button>
      </Box>
      )
  } else {
    return (
      <Box
        sx={{
          height: { xs: 230, md: 190 },
          width: { xs: 230, md: 190 },
          border: 1,
          borderColor: 'rgb(150, 150, 150)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px'
        }}
      >
        <Typography>
          Image {props.index + 1}
        </Typography>
      </Box>
    )
  }
})

export default RenderImage4 = forwardRef((props, ref) => {
  const { visible, children, isFlex, autoSave, ...other } = props;
  const isLargeScreen = useMediaQuery('(min-width:600px)');
  const [showSpinner, setShowSpinner] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [imageUrls, setImageUrls] = useState(["", "", "", ""]); //https://source.unsplash.com/random?auto=format&w=400&dpr=2&r=" + Math.random());
  const [prompt, setPrompt] = useState("");
  const [prompts, setPrompts] = useState([]);
  const [progress, setProgress] = useState(0);
  const [dimageGroupUuid, setDimageGroupUuid] = useState("");
  const [imageUuids, setImageUuids] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const uc = useContext(UserContext);
  const blank = "none";
  const [creativity, setCreativity] = useState(50);
  const [snackOpen, setSnackOpen] = useState(false);
  const [message, setMessage] = useState("")
  const refImage1 = useRef(null);
  const refImage2 = useRef(null);
  const refImage3 = useRef(null);
  const refImage4 = useRef(null);

  useEffect(() => {  

    axPub("./di1Prompts.yaml", "yaml").then(data => {
      setPrompts(data.prompts.freestyle);
    });
  }, []);

  useImperativeHandle(ref, () => ({
    async handleClose() {
      return handleSaveSelected();
    }
  }));

  const handleMakeImage = async () => {

    ReactGA.event({category: "click", action: "render-4"})
    await handleSaveSelected();

    setProgress(0);
    setShowSpinner(true);
    const startTime = Date.now();
    const maxSecs = 90;
    const expectSecs = 40;
    const intervalSecs = 1;

    // call api to create image supplying metadata
    var timer;
    axApiContext(
      uc, 
      "POST", 
      Urls.dimage, 
      {metadata: {prompt: prompt.trim(), creativity}, kind: "multi_t2i"}).then(
      responsePost => {

        setImageUuids(responsePost.dimages.map(x => x.uuid));
        setDimageGroupUuid(responsePost.uuid);

        setShowImages(false);

        timer = setInterval(() => {
          if (!visible) {
            // HACK: do nothing if not visible to fix race condition
            clearInterval(timer);
            return;
          }

          // call api until status is rendered
          axApiContext(
            uc,
            "GET", 
            Urls.dimage + "/" + responsePost.uuid).then(responseGet => {

            if (responseGet.status == "rendered") {
              setShowSpinner(false);
              clearInterval(timer);
              setImageUrls(responseGet.dimages.map(x => x.url));
              setShowImages(true);
            } else {
              setProgress((p) => (p + 100/expectSecs))
              const millis = Date.now() - startTime;
              if (millis > maxSecs * 1000) {
                // times up!
                handleError(timer);
              }
            }
          }).catch(e => {
            handleError(timer);
          });
        }, intervalSecs * 1000);
    }).catch(e => {
      handleError(timer);
    });
  }

  const handleError = (timer) => {
    clearInterval(timer);
    setShowSpinner(false);
    setMessage("Oops! Can't render at this time, please try again")
    setSnackOpen(true);
  }

  const handleSaveSelected = async () => {

    var uuids = [];
    if (refImage1.current.getState()) {
      uuids.push(imageUuids[0]);
    }
    if (refImage2.current.getState()) {
      uuids.push(imageUuids[1]);
    }
    if (refImage3.current.getState()) {
      uuids.push(imageUuids[2]);
    }
    if (refImage4.current.getState()) {
      uuids.push(imageUuids[3]);
    }
    if (uuids.length > 0) {
      // call API to select the images
      axApiContextOptional(uc,
        "PUT", 
        Urls.dimage, 
        {groupUuid: dimageGroupUuid, selectedImageUuids: uuids}).then(
        responsePost => {
          // do anything on success? message?
      })
    }
    refImage1.current.setState(false);
    refImage2.current.setState(false);
    refImage3.current.setState(false);
    refImage4.current.setState(false);
  }

  const handleSelect = (newPrompt) => {
    setPrompt(p => (p ? p + " " : "") + newPrompt)
  };

  return (
    <Box sx={{width: {xs: 250, md: 850}}} paddingTop={1}>
      <Grid container direction="row" spacing={1} wrap="wrap">
        <Grid item md={6}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <TextField
                label="Describe your scene"
                placeholder="Describe your scene"
                multiline
                fullWidth
                rows={6}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    handleMakeImage();
                    ev.preventDefault();
                  }
                }}
              />
            </Grid>  
            <Grid item>
              <Grid container direction="row" spacing={1}>
                {prompts?.map((prompt, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <PromptMenu title={prompt.title} texts={prompt.values} onSelect={handleSelect} />
                </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              Dramatic Control
              <Slider 
                aria-label="steps"
                size="small"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={0}
                max={100}
                value={creativity}
                onChange={(e) => setCreativity(e.target.value)}
              >
              </Slider>
              <Grid item alignSelf={'center'}>
                <Typography sx={{ fontWeight: 'light', fontStyle: 'italic' }}>
                  Default value of 50 for well-balanced images
                </Typography>
              </Grid>  
            </Grid>  
            <Grid item xs={1}>
              <Button variant="contained" onClick={handleMakeImage} sx={{width: 150, bottom: 3}} disabled={!prompt}>Create</Button>
            </Grid>            
            <Grid item>
              {showSpinner ? <LinearProgress sx={{width: {xs: 250, md: 400}, height: 8}} variant="determinate" value={progress}/>: ""}
            </Grid>            
          </Grid>
        </Grid>
        <Grid item xs={6} md={6} spacing={1}>
          <Grid container direction="column">
            <Grid item>
              <Grid container direction="row">
                <Grid item>
                  <ImageInstance index={0} imageUrl={imageUrls[0]} showImages={showImages} ref={refImage1}/>
                </Grid>
                <Grid item>
                  <ImageInstance index={1} imageUrl={imageUrls[1]} showImages={showImages} ref={refImage2}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row">
                <Grid item>
                  <ImageInstance index={2} imageUrl={imageUrls[2]} showImages={showImages} ref={refImage3}/>
                </Grid>
                <Grid item>
                  <ImageInstance index={3} imageUrl={imageUrls[3]} showImages={showImages} ref={refImage4}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={snackOpen}
        autoHideDuration={4000}
        onClose={() => {setSnackOpen(false)}}
        message={message}
      />
    </Box>
  );
});

function RenderImage4(props, ref) {
}