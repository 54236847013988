import { Box, Button, Card, CardActions, CardContent, CardMedia, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, useEffect, useImperativeHandle, useState }  from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const Crop = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState(null);
  const matches = useMediaQuery('(min-width:600px)');
  const size = matches ? 500 : 280;

  useEffect(() => {
    setCrop({
      unit: 'px', 
      x: props.cropRect.x * size,
      y: props.cropRect.y * size,
      width: props.cropRect.w * size,
      height: props.cropRect.h * size
    });

    window.addEventListener("resize", () => {
    }, false);

  }, [props.cropRect]);

  useImperativeHandle(ref, () => ({
    open() {
      handleClickOpen();
    }
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
      return;
    setOpen(false);
    props.handleCropped({x: crop.x / size, y: crop.y / size, w: crop.width / size, h: crop.height / size});
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{ sx: { minWidth: {size}}}}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Crop
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <ReactCrop style={{width: size}} crop={crop} onChange={c => setCrop(c)} aspect={props.cropRect.w / props.cropRect.h}>
            <img src={props.imageUrl} />
          </ReactCrop>
        </DialogContent>
      </Dialog>
    </div>);
});
