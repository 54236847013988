import * as React from 'react';
import { AppBar, Box, Button, InputBase, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useRef, useContext }  from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";

import HamburgerMenu from './HamburgerMenu';
import DialogComingSoon from './DialogComingSoon';
import { DialogSignin } from './DialogSignin';
import { DialogSignup } from './DialogSignup';
import { DialogHowItWorks } from 'components/DialogHowItWorks';
import ProfileMenu from './ProfileMenu';
import { UserContext } from 'UserContext';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function TopBar() {  
  const dialogEl = useRef(null);
  const signinEl = useRef(null);
  const signupEl = useRef(null);
  const howItWorksEl = useRef(null);
  const uc = useContext(UserContext);
  let navigate = useNavigate();
  const isLargeScreen = useMediaQuery('(min-width:600px)');
  
  React.useEffect(() => {
  }, [])

  const handleOpenSignin = () => {
    signinEl.current.open();
  }

  const handleOpenSignup = () => {
    signupEl.current.open();
  }

  const signedInUpUpdateHandler = () => {
  }

  const handleHowItWorks = () => {
    howItWorksEl.current.open();
  }

  return <AppBar position="fixed" color="cover">
    <Toolbar variant="dense">
      {uc?.context.loggedIn &&
      <HamburgerMenu></HamburgerMenu>
      }
      {false &&
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search>
      }
      <Typography variant="h6" component="div" sx={{ flexGrow: 1}} to="/" underline="none">
        <Box onClick={() => {navigate("/")}}>
          <Typography variant="logo">
            Panoramix
          </Typography>
        </Box>
      </Typography>
      {uc?.context.loggedIn && isLargeScreen &&
        <Box sx={{paddingRight: 2, marginTop: 0.5}}>
          <Button color="text" variant="text" onClick={handleHowItWorks}>How It Works</Button>
        </Box>
      }
      {uc?.context.loggedIn && isLargeScreen &&
        <Typography paddingRight={3}>{uc?.context.name} </Typography> 
      }
      {!uc?.context.loggedIn && 
        <Button color="text" variant="text" onClick={handleOpenSignin}>Sign In</Button>
      }
      {!uc?.context.loggedIn && 
        <Button color="text" variant="outlined" onClick={handleOpenSignup}>Get Started</Button> 
      }
      {uc?.context.loggedIn && 
        <ProfileMenu signedInUpUpdateHandler={signedInUpUpdateHandler}></ProfileMenu>
      }
    </Toolbar>
    <DialogComingSoon ref={dialogEl}></DialogComingSoon>
    <DialogSignin ref={signinEl} signedInUpUpdateHandler={signedInUpUpdateHandler}></DialogSignin>
    <DialogSignup ref={signupEl} signedInUpUpdateHandler={signedInUpUpdateHandler}></DialogSignup>
    <DialogHowItWorks ref={howItWorksEl}></DialogHowItWorks>
  </AppBar>;
}
