import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, useContext, useEffect, useImperativeHandle } from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import ReactGA from 'react-ga4';

import SignupEmail from './SignupEmail';
import { googleClientId, fbClientId, signupWithFB, signupWithGoogle, UserContext } from 'UserContext.tsx';

import AppInfo from 'AppInfo.json';

ReactGA.initialize(AppInfo.gtag);


const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const DialogSignup = forwardRef((props, ref) => {
  const [openState, setOpenState] = React.useState(false);
  const [showEmailSignup, setEmailShowEmailSignup] = React.useState(false);
  const uc = useContext(UserContext);

  useEffect(() => {
    /*
    const initClient = () => {
      gapi.client.init({
      clientId: googleClientId,
      scope: ''
    });
    };
    gapi.load('client:auth2', initClient);
    */
  });
  
  useImperativeHandle(ref, () => ({
    open() {
      handleOpen();
    }
  }));

  const handleOpen = () => {
    setOpenState(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
    setOpenState(false);
    setEmailShowEmailSignup(false);
  };

  const handleShowEmailSignup = () => {
    setEmailShowEmailSignup(true);
  }

  const signedInUpUpdateHandler = () => {
    props.signedInUpUpdateHandler();
    ReactGA.event({category: "click", action: "signup"})
    handleClose();
  }

  const handleFacebookCallback = (response) => {
    ReactGA.event({category: "click", action: "signup"})
    signupWithFB(uc, response.name, response.userID, response.accessToken, response.signedRequest);
  }
     
  const handleGoogleSuccess = (response) => {
    ReactGA.event({category: "click", action: "signup"})
    signupWithGoogle(uc, response.credential, response.clientId);  
  }

  const handleGoogleFailure = (response) => {
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openState}
        sx={{height: 600}}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Sign Up
        </BootstrapDialogTitle>
        <DialogContent dividers>

          <Grid container direction="column" justifyContent="flex-start" alignItems="left" rowGap={2}>
            <Grid item>
              <Typography gutterBottom>
              Sign Up with Google, Facebook, or use your email and password
              </Typography>
            </Grid>
            {showEmailSignup &&
            <Grid item>
              <SignupEmail signedInUpUpdateHandler={signedInUpUpdateHandler}></SignupEmail>
            </Grid>}
            {!showEmailSignup &&
            <Grid item>
              
            </Grid> }

            {!showEmailSignup &&
            <Grid item>
              <FacebookLogin
                appId={fbClientId}
                autoLoad={false}
                fields="name,email"
                callback={handleFacebookCallback}
                />
            </Grid>
            }

            {!showEmailSignup &&
            <Grid item>
              <GoogleOAuthProvider clientId={googleClientId}>
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleFailure}
                />
              </GoogleOAuthProvider>
            </Grid>
            }

            {!showEmailSignup &&
              <Button autoFocus variant="outlined" color="text" onClick={handleShowEmailSignup}>
                Sign Up with email
              </Button>
            }
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
});
