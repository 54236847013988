import { Box, Button, Card, CardContent, CardMedia, Paper, TextField, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useContext, useRef, useEffect, useState }  from 'react';

import CookieConsent from "react-cookie-consent";
import { axPub, axApi } from 'utils/api';

import logo_black from 'assets/logox_black.svg'
import logo_white from 'assets/logox_white.svg'
import { CustomThemeContext, darkPalette, lightPalette } from 'theme';
import { DialogSignin } from 'components/DialogSignin';
import { DialogSignup } from 'components/DialogSignup';
import { DialogAboutUs } from 'components/DialogAboutUs';
import { DialogHowItWorks } from 'components/DialogHowItWorks';
import DialogRender from 'components/DialogRender';
import { PrivacyPolicyContent } from 'components/PrivacyPolicyContent';

export default function PrivacyPolicyNoUser() {
  const signinEl = useRef(null);
  const signupEl = useRef(null);
  const aboutUsEl = useRef(null);
  const howItWorksEl = useRef(null);
  const refDialogRender = useRef(null);
  const [sliderImages, setSliderImages] = useState([]);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [products, setProducts] = useState([]);
  const [prompt, setPrompt] = useState("");
  const isLargeScreen = useMediaQuery('(min-width:850px)');
  const { currentTheme } = useContext(CustomThemeContext);
  const [palette, setPalette] = useState(currentTheme == "dark" ? darkPalette : lightPalette);

  useEffect(() => {
    axPub("./wwwSliderImages.v2.yaml", "yaml").then((data) => {
      setSliderImages(data);
      let interval = null;
      if (!interval) {
        interval = setInterval(() => {
          setSliderIndex(p => (p + 1) % data.length)
        }, 4000);
      }
      return () => clearInterval(interval);
    });
    axApi("GET", "./products", "", {grouped: "1"}).then(data => setProducts(data));
  }, []);

  const updateSlider = () => {
    if (sliderImages.length > 0) {
      setSliderIndex(p => (p + 1) % sliderImages.length)
    }
  }

  const handleRender = () => {
    refDialogRender.current.open()
  }

  const renderCloseCb = () => {
  }

  const handleHowItWorks = () => {
    howItWorksEl.current.open();
  }

  const handleAboutUs = () => {
    aboutUsEl.current.open();
  }

  const handleOpenSignin = () => {
    signinEl.current.open();
  }

  const handleOpenSignup = () => {
    signupEl.current.open();
  }

  const signedInUpUpdateHandler = () => {
  }

  return (
    <>
      <Grid container direction="column">
        { isLargeScreen &&
        <Grid item>
          <Grid container padding={0} direction="row" justifyContent={"space-between"} style={{ color: palette.cover.text, backgroundColor: palette.cover.background, borderBottom: '1px solid black' }}>
            <Grid item marginLeft={1.8} sx={{padding: 1.7}}>
              <Typography variant="logo">
                Panoramix
              </Typography>
            </Grid>
            <Grid item sx={{padding: 2}}>
              <Grid container direction="row" spacing={3}>
                <Grid item marginTop={0.5}>
                  <Button color="text" variant="text" onClick={handleHowItWorks}>How It Works</Button>
                </Grid>
                <Grid item marginTop={0.5}>
                  <Button color="text" variant="text" onClick={handleAboutUs}>About Us</Button>
                </Grid>
                <Grid item marginTop={0.5}>
                  <Button color="text" variant="text" onClick={handleOpenSignin}>Sign In</Button>
                </Grid>
                <Grid item  marginTop={0.3}>
                  <Button color="text" variant="outlined" onClick={handleOpenSignup} sx={{borderRadius: 10}}>Get Started</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        }
        { !isLargeScreen &&
        <Grid item>
          <Grid container paddingTop={1.5} paddingLeft={2} paddingBottom={1.5} direction="row" style={{ color: palette.cover.text, backgroundColor: palette.cover.background, borderBottom: '1px solid black' }}>
            <Grid item flex={5}>
              <Typography variant="logo">
                Panoramix
              </Typography>
            </Grid>
            <Grid item flex={2} marginTop={0.6}>
              <Button color="text" variant="text" onClick={handleOpenSignin}>Sign In</Button>
            </Grid>
            <Grid item flex={3} marginTop={0.6} marginRight={-3}>
              <Button color="text" variant="text" onClick={handleOpenSignup}>Sign Up</Button>
            </Grid>
          </Grid>
        </Grid>
        }
        <Grid item margin={3}>
          <Typography variant="h4">Privacy Policy</Typography>
        </Grid>
        <Grid item margin={3}>
          <PrivacyPolicyContent></PrivacyPolicyContent>
        </Grid>
      </Grid>

      <DialogSignin ref={signinEl} signedInUpUpdateHandler={signedInUpUpdateHandler}></DialogSignin>
      <DialogSignup ref={signupEl} signedInUpUpdateHandler={signedInUpUpdateHandler}></DialogSignup>
      <DialogAboutUs ref={aboutUsEl}></DialogAboutUs>
      <DialogHowItWorks ref={howItWorksEl}></DialogHowItWorks>
      <DialogRender ref={refDialogRender} closeCb={renderCloseCb} mode="single" showPrompts={false} prompt={prompt}></DialogRender>
    </>
  );
}
