import { Link, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import { DialogGeneric } from './DialogGeneric';

const body1 = `Thank you for visiting our website.  We are committed to providing our customers with the best possible service, so please don't hesitate to reach out to us with any questions, comments or concerns.

If you have any questions or need assistance, please email us at:

`

const email = "support@panoramix.art"

const body2 = `
Please provide your name, phone number, message and one of our experienced team members will get back to you as soon as possible.
  
Alternatively, you can call us at if you need immediate help:

+1 (401) 314-3907

We look forward to hearing from you!`


// eslint-disable-next-line
export const DialogContactUs = forwardRef((props, ref) => {
  const genericEl = useRef(null);

  useImperativeHandle(ref, () => ({
    open() {
      genericEl.current.open();
    }
  }));

  return (
    <DialogGeneric ref={genericEl} title="Contact Us / Support">
      <Grid container direction="column" alignItems="center" rowGap={3}>
        <Grid item>
          <Typography style={{whiteSpace: 'pre-line'}} align="left">
            {body1}
          </Typography>
        </Grid>
        <Grid item style={{color: "white"}}>
          <Link href={`mailto: ${email}`}>{email}</Link>
        </Grid>
        <Grid item>
          <Typography style={{whiteSpace: 'pre-line'}} align="left">
            {body2}
          </Typography>
        </Grid>        
      </Grid>
    </DialogGeneric>
  );
});

