import { Route, Routes, Navigate } from "react-router-dom";
import { useEffect }  from 'react';
import { Box, Paper } from '@mui/material';
import SearchResults from './screens/SearchResults.js'
import Report from './screens/Report'
import Follow from './screens/Follow.js'
import Import from './screens/Import.js'
import OrderHistory from './screens/OrderHistory.js'
import OrderHistoryItem from './screens/OrderHistoryItem.js'
import Account from './screens/Account.js'
import Profile from './screens/Profile.js'
import Cart from './screens/Cart.js'
import Editor from './screens/Editor.js';
import Gallery from './screens/Gallery.js';
import Lightbox from './screens/Lightbox.js';
import HomeNoUser from './screens/HomeNoUser.js'
import Products from './screens/Products.js';
import Product from './screens/Product.js';
import Checkout from './screens/Checkout.js';
import PrivacyPolicy from './screens/PrivacyPolicy';
import PrivacyPolicyNoUser from './screens/PrivacyPolicyNoUser';
import {HomeWorker} from './screens/HomeWorker.js';
import { BrowserRouter } from "react-router-dom";
import { useContext, ReactElement }  from 'react';
import { UserContext, userPersonaWorker, userPersonaEndUser } from 'UserContext';
import { propsToClassKey } from "@mui/styles";
import ScrollToTop from "utils/ScrollToTop.js";
import ReactGA from 'react-ga4';

import TopBar from 'components/TopBar';
import Footer from 'components/Footer';
import AppInfo from 'AppInfo.json';

ReactGA.initialize(AppInfo.gtag);

export default function Routess(props:any) {

  const uc = useContext(UserContext);

  useEffect(() => {
  }, []);

  const ProtectedRoute = (props: any) => {
    ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});

    if (!uc?.context.loggedIn) {
      return <Navigate to="/" />;
    }
    return (<>
        <TopBar></TopBar>
        <Box minHeight="100vh" paddingTop={6.5} >
          <Paper sx={{padding: {xs:1, md: 2}, margin: {xs:1, md: 1}, bgcolor: "background.default"}}>
            {props.children}
          </Paper>
        </Box>
        <Footer></Footer>
      </>);
  };

  const UnProtectedRoute = (props: any) => {
    ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});
    return (<>{props.children}</>);
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>        
        { !uc?.context.loggedIn && 
          <Route path="/" element={<UnProtectedRoute><HomeNoUser /></UnProtectedRoute>} />
        }
        { uc?.context.loggedIn && uc?.context.persona === userPersonaWorker && 
          <Route path="/" element={<ProtectedRoute><HomeWorker /></ProtectedRoute>} />
        }
        { uc?.context.loggedIn && uc?.context.persona === userPersonaEndUser && 
          <Route path="/" element={<ProtectedRoute><Gallery /></ProtectedRoute>} />
        }
        { !uc?.context.loggedIn && 
          <Route path="/policy" element={<UnProtectedRoute><PrivacyPolicyNoUser /></UnProtectedRoute>} />
        }
        { uc?.context.loggedIn && 
          <Route path="/policy" element={<ProtectedRoute><PrivacyPolicy /></ProtectedRoute>} />
        }
        
        <Route path="/search" element={<ProtectedRoute><SearchResults /></ProtectedRoute>}/>
        <Route path="/search/:searchText" element={<ProtectedRoute><SearchResults /></ProtectedRoute>} />
        <Route path="/follow" element={<ProtectedRoute><Follow /></ProtectedRoute>}  />
        <Route path="/orders" element={<ProtectedRoute><OrderHistory /></ProtectedRoute>}  />
        <Route path="/order" element={<ProtectedRoute><OrderHistoryItem /></ProtectedRoute>}  />
        <Route path="/cart" element={<ProtectedRoute><Cart /></ProtectedRoute>} />
        <Route path="/account" element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path="/gallery" element={<ProtectedRoute><Gallery /></ProtectedRoute>}  />
        <Route path="/lightbox" element={<ProtectedRoute><Lightbox /></ProtectedRoute>}  />
        <Route path="/products" element={<ProtectedRoute><Products /></ProtectedRoute>}  />
        <Route path="/product" element={<ProtectedRoute><Product /></ProtectedRoute>}  />
        <Route path="/editor" element={<ProtectedRoute><Editor /></ProtectedRoute>}  />
        <Route path="/checkout" element={<ProtectedRoute><Checkout /></ProtectedRoute>} />
      </Routes>
    </BrowserRouter>);
}

