import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, IconButton, Paper, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState }  from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FileUploadIcon from '@mui/icons-material/FileUpload';

//import { default as Lightbox2 } from 'react-spring-lightbox';
//import { Carousel } from 'react-responsive-carousel';
import { default as Carousel } from 'react-material-ui-carousel';

import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const images = [
  {
     src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
     width: 320,
     height: 174,
     isSelected: false,
     thumbnailCaption: "After Rain (Jeshu John - designerspics.com)",

  },
  {
     src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
     width: 320,
     height: 212,
     tags: [
        { value: "Ocean", title: "Ocean" },
        { value: "People", title: "People" },
     ],
     alt: "Boats (Jeshu John - designerspics.com)",
  },
  {
     src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
     width: 320,
     height: 212,
  },
  {
     src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
     width: 320,
     height: 212,
  }, 
  {
     src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
     width: 320,
     height: 212,
  },
  {
     src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
     width: 320,
     height: 212,
  },
  {
     src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
     width: 320,
     height: 212,
  },
];

export default function Lightbox() {
  const [currentImageIndex, setCurrentIndex] = useState(0);

  const lightGallery = useRef(null);

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < images.length &&
    setCurrentIndex(currentImageIndex + 1);

  useEffect(() => {  


  }, []);

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;

      lightGallery.current.openGallery();

    }
  }, []);

  function Pix(props)
  {
    return (
      <Carousel>
        { images.map( (item, i) => <Item key={i} item={item} imageUrl={item.src} /> ) }
      </Carousel>
    )
  }

  function Item(props)
  {
    return (
      <Box
        component="img"
        sx={{
          //height: { xs: 400, md: 400 },
          width: "100%",
        }}
        src={props.imageUrl}
      />
    )
  }

  return (
    <div>
      <Grid container direction="column" spacing={1} margin={0}>
        <Grid item>
          <Typography variant="h4">
            Lightbox
          </Typography>
        </Grid>
        <Grid item>
          <Paper sx={{padding: {xs:1, md: 3}, marginRight: 2, bgcolor: "background.default"}}>
  
          </Paper>
        </Grid>
        <Grid item>
          <div>
            <LightGallery licenseKey='6000-0000-000-0001' plugins={[lgThumbnail, lgZoom]} dynamic={true} 
            onInit={onInit}
            dynamicEl= {[
              {
                src: 'https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg',
                thumb: 'https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg',
                subHtml: '<h4>Image 1 title</h4><p>Image 1 descriptions.</p>',
              },
              {
                src: 'https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg',
                thumb: 'https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg',
                subHtml: '<h4>Image 1 title</h4><p>Image 1 descriptions.</p>',
              },
              {
                src: 'https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg',
                thumb: 'https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg',
                subHtml: '<h4>Image 1 title</h4><p>Image 1 descriptions.</p>',
              }
            ]}
            >
            </LightGallery>
          </div>
        </Grid>
      </Grid>
    </div>);
}


/*
            <Lightbox2
              isOpen={false}
              onPrev={gotoPrevious}
              onNext={gotoNext}
              images={images}
              currentIndex={currentImageIndex} />

            {
            <Carousel showArrows={true}>

              {images.map(image => {
                  <div>
                    <img src={image?.src} />
                 </div>
              })}
              
            </Carousel>
xx
            <LightGallery
                licenseKey='6000-0000-000-0001'
            >
               {images.map(image => { 

<Box
component="img"
sx={{
  height: { xs: 400, md: 400 },
  width: "100%",
}}
src={image?.src}
/>

                  <a href="img/img1.jpg">
                    <img src={image?.src} />
                  </a>
})}

</LightGallery>
yy

*/