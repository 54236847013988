import { Dialog, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState }  from 'react';
import { useSearchParams } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';

import RenderImageI2I from 'components/RenderImageI2I.js';


// eslint-disable-next-line
export default DialogRenderI2I = forwardRef((props, ref) => {
  const renderRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openRender, setOpenRender] = useState(false);

  useImperativeHandle(ref, () => ({
    open() {
      handleOpenRender();
    }
  }));

  useEffect(() => {
  }, []);

  const handleOpenRender = () => {
    setOpenRender(true);
  };

  const handleCloseRender = async () => {
    setOpenRender(false);
    await renderRef.current.handleClose();
    if (props?.closeCb) {
      setTimeout(function() {
        props.closeCb();
      }, 500);
    }
  };

  return (
    <Dialog
      open={openRender}
      onClose={handleCloseRender}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='md'
      fullwidth='true'
    >
      <DialogTitle id="alert-dialog-title">
        {"Image-to-image"}

        <IconButton
          onClick={() => { window.open("http://help.panoramix.art", '_blank')} }
          sx={{
            position: 'absolute',
            right: 38,
            top: 8,
          }}>
          <Tooltip title="Help">
            <HelpIcon></HelpIcon>
          </Tooltip>
        </IconButton>

        <IconButton
          aria-label="close"
          onClick={handleCloseRender}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent >
        <RenderImageI2I visible={openRender} isFlex={true} ref={renderRef} autoSave={false} firstImage={props.firstImage}/>
      </DialogContent>
    </Dialog>
  );
});

function DialogRenderI2I(props, ref) {
}