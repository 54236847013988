import * as React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, useContext, useImperativeHandle} from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga4';

import FacebookLogin from 'react-facebook-login';
import SigninEmail from './SigninEmail';
import { fbClientId, googleClientId, signinWithFB, signinWithGoogle, UserContext } from 'UserContext.tsx';
import AppInfo from 'AppInfo.json';

ReactGA.initialize(AppInfo.gtag);

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const DialogSignin = forwardRef((props, ref) => {
  const [openState, setOpenState] = React.useState(false);
  const [showEmailSignin, setShowEmailSignin] = React.useState(false);
  const uc = useContext(UserContext);

  useImperativeHandle(ref, () => ({
    open() {
      handleOpen();
    }
  }));

  const handleOpen = () => {
    setOpenState(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
      return;
    setOpenState(false);
    setShowEmailSignin(false);
  };

  const handleShowEmailSignin = () => {
    setShowEmailSignin(true);
  }

  const signedInUpUpdateHandler = () => {
    props.signedInUpUpdateHandler();
    ReactGA.event({category: "click", action: "signin"})
    handleClose();
  }

  const handleFacebookCallback = async (response) => {
    ReactGA.event({category: "click", action: "signin"})
    signinWithFB(uc, response.name, response.userID, response.accessToken, response.signedRequest);
  }
     
  const handleGoogleSuccess = async (response) => {
    ReactGA.event({category: "click", action: "signin"})
    signinWithGoogle(uc, response.credential, response.clientId);  
  }

  const handleGoogleFailure = (response) => {
    // TODO: error message
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openState}
        sx={{height: 600}}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Sign In
        </BootstrapDialogTitle>
        <DialogContent dividers>

          <Grid container direction="column" justifyContent="flex-start" alignItems="left" rowGap={2}>
            <Grid item>
              <Typography gutterBottom>
              Sign In with Google, Facebook, or use your email and password
              </Typography>
            </Grid>
            {showEmailSignin &&
            <Grid item>
              <SigninEmail signedInUpUpdateHandler={signedInUpUpdateHandler}></SigninEmail>
            </Grid>}

            {!showEmailSignin &&
            <Grid item>
              <FacebookLogin
                appId={fbClientId}
                autoLoad={false}
                fields="name,email"
                callback={handleFacebookCallback} />
            </Grid>
            }

            {!showEmailSignin &&
            <Grid item>
              <GoogleOAuthProvider clientId={googleClientId}>
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleFailure}
                />
              </GoogleOAuthProvider>
            </Grid>
            }

            {!showEmailSignin &&
              <Button autoFocus variant="outlined" color="text" onClick={handleShowEmailSignin}>
                Sign In with email
              </Button>
            }
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
});
