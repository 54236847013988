import React, { useContext, useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { Box, Button, Divider, Grid, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  useStripe,
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  Elements
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { axApiContext, UserContext } from 'UserContext';
import { CommentsDisabledOutlined } from "@mui/icons-material";

// StripeInput needed to wrap the stripe iFrames
const StripeInput = forwardRef((props, ref) => {
  const { component: Component, ...other } = props;
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => {
      return {
        focus() {
          inputRef.current.focus();
        },
        scrollIntoView() {
          inputRef.current.scrollIntoView();
        },
      };
    }, []);

  return <Component 
      onReady={element => (ref.current = element)} 
      onFocus={element => (ref.current = element)} 
      onClick={element => (ref.current = element)} 
      {...other} />;
});


export default function CheckoutCC({handleBack, handleNext}) {

  const [stripePromise, setStripePromise] = useState(null)
  const [clientSecret, setClientSecret] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [cartOrder, setCartOrder] = useState({});
  const uc = useContext(UserContext);

  useEffect(() => {
    if (stripePromise === null || clientSecret === null || publicKey === null) {
      getStripeKeys();
    }
  }, [])

  const getStripeKeys = async () => {
  
    console.log("GET /cart")
    const c = await axApiContext(uc, 'GET', '/cart');
    setCartOrder(c)

    const c1 = await axApiContext(uc, 'GET', '/cart/payment/stripe/pk');
    setPublicKey(c1.pk);

    const c2 = await axApiContext(uc, 'GET', '/cart/payment/stripe');
    setClientSecret(c2.clientSecret);

    const stripe = loadStripe(c1.pk);
    setStripePromise(stripe)
  }

  return (
    <>
      { !(stripePromise && clientSecret) && <></> }
      { stripePromise && clientSecret &&
        <Elements stripe={stripePromise} options={{clientSecret: clientSecret}}>
          <Typography>
          </Typography>
          <CheckoutCC2 handleBack={handleBack} handleNext={handleNext} cartOrder={cartOrder} clientSecret={clientSecret}></CheckoutCC2>
        </Elements>
      }
    </>
  ); 
}

function CheckoutCC2({handleBack, handleNext, cartOrder, clientSecret}) {

  const stripe = useStripe();
  const elements = useElements();
  const [errorMsg, setErrorMsg] = useState("");
  const uc = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const cardsLogo = [
    "amex",
    "cirrus",
    "diners",
    "discover",
    "mastercard",
    "visa",
    "visaelectron",
  ];

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setLoading(true);

    const card = elements.getElement(CardNumberElement);

    const {paymentIntent, error} = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: card,
          billing_details: {
            address: {
              city: cartOrder?.billingAddress?.city,
              line1: cartOrder?.billingAddress?.address1,
              line2: cartOrder?.billingAddress?.address2,
              postal_code: cartOrder?.billingAddress?.postalCode,
              state: cartOrder?.billingAddress?.state
            },
            email: cartOrder?.user?.email,
            name: cartOrder?.billingAddress?.firstName + " " + cartOrder?.billingAddress?.lastName,  
            phone: null
          }
        },
        shipping: {
          address: {
            city: cartOrder?.shippingAddress?.city,
            line1: cartOrder?.shippingAddress?.address1,
            line2: cartOrder?.shippingAddress?.address2,
            postal_code: cartOrder?.shippingAddress?.postalCode,
            state: cartOrder?.shippingAddress?.state
          },
          name: cartOrder?.shippingAddress?.firstName + " " + cartOrder?.shippingAddress?.lastName,
        }
      }
    )
    if (error) {
      setErrorMsg(error?.message);
      console.log("error on submit", error);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      // submit
      await axApiContext(uc, 'POST', '/cart/payment/stripe');
      handleNext();
    }
    setLoading(false);
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2} margin={1} marginTop={6}>
          <Grid item marginTop={0} marginBottom={2} paddingBottom={0} sx={{width: {xs: '100%', md: '25%'}}}>
            <Table sx={{padding:1, margin:0}}>
              <TableBody>
                <TableRow>
                  <TableCell sx={{padding:1, textAlign: "right"}}>
                    <Typography sx={{fontWeight: 'bold'}}>
                      Subtotal:
                    </Typography>
                  </TableCell>
                  <TableCell sx={{padding:1}}>
                    <Typography sx={{fontWeight: 'bold'}}>
                      ${cartOrder?.subtotal}
                    </Typography>
                  </TableCell>
                </TableRow>
                { cartOrder.tax && cartOrder.discount !== "0.00" &&
                <TableRow>
                  <TableCell sx={{padding:1, textAlign: "right"}}>
                    <Typography sx={{fontWeight: 'bold'}}>
                      Discount:
                    </Typography>
                  </TableCell>
                  <TableCell sx={{padding:1}}>
                    <Typography sx={{fontWeight: 'bold'}}>
                      ${cartOrder?.discount}
                    </Typography>
                  </TableCell>
                </TableRow>
                }
                <TableRow>
                  <TableCell sx={{padding:1, textAlign: "right"}}>
                    <Typography sx={{fontWeight: 'bold'}}>
                      Shipping:
                    </Typography>
                  </TableCell>
                  <TableCell sx={{padding:1}}>
                    <Typography sx={{fontWeight: 'bold'}}>
                      ${cartOrder?.shipping}
                    </Typography>
                  </TableCell>
                </TableRow>
                { cartOrder.tax && cartOrder.tax !== "0.00" &&
                <TableRow>
                  <TableCell sx={{padding:1, textAlign: "right"}}>
                    <Typography sx={{fontWeight: 'bold'}}>
                      Tax:
                    </Typography>
                  </TableCell>
                  <TableCell sx={{padding:1}}>
                    <Typography sx={{fontWeight: 'bold'}}>
                      ${cartOrder?.tax}
                    </Typography>
                  </TableCell>
                </TableRow>
                }
                <TableRow>
                  <TableCell sx={{padding:1, textAlign: "right"}}>
                    <Typography sx={{fontWeight: 'bold'}}>
                      Total:
                    </Typography>
                  </TableCell>
                  <TableCell sx={{padding:1}}>
                    <Typography sx={{fontWeight: 'bold'}}>
                      ${cartOrder?.total}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item>
            <Divider></Divider>
          </Grid>
          <Grid container item xs={12} sm={9} justify="space-between">
            {cardsLogo.map(e => <img key={e} src={`./cards/${e}.png`} alt={e} width="50px" align="bottom" style={{ padding: "0 5px" }} />)}
          </Grid>
          <Grid item sx={{width: '300px'}} marginTop={3}>
            <TextField
              label="Credit Card Number"
              name="ccnumber"
              variant="outlined"
              required
              fullWidth
              InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                      component: CardNumberElement
                  },
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid container direction="row" marginTop={2} marginLeft={-1} spacing={3}>
            <Grid item sx={{width: '110px'}}>
              <TextField
                label="Expiration"
                name="ccexp"
                variant="outlined"
                required
                fullWidth
                InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                        component: CardExpiryElement
                    },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item sx={{width: '110px'}}>
              <TextField
                label="CVC"
                name="cccvc"
                variant="outlined"
                required
                fullWidth
                InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                        component: CardCvcElement
                    },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid container item direction="row" marginTop={0} marginLeft={-1} spacing={2}>
            <Grid item>
              <Button 
                color="inherit"
                autoFocus 
                onClick={handleBack} 
                variant="outlined"
                sx={{ mr: 1 }}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button autoFocus onClick={handleSubmit} disabled={loading} variant="outlined">
                Submit Payment
              </Button>
            </Grid>
            <Grid item>
              { loading &&
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              }
            </Grid>
          </Grid>
          { errorMsg &&
            <Grid item marginTop={0.75}>
              <Typography>
                {errorMsg}
              </Typography>
            </Grid>
          }
        </Grid>
      </form>
    </>
  );
}
