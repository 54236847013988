import { Button, Card, CardContent, CardMedia, Box, Typography, Container, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useEffect, useRef, useState  }  from 'react';
import { axApiContext, UserContext } from 'UserContext';


export default function SearchResults() {
  const [results, setResults] = useState(null);
  const [searchText, setSearchText] = useState("");
  const uc = useContext(UserContext);
  const searchTextRef = useRef(null);

  useEffect(() => {
    searchTextRef.current.focus();
  }, [])

  const search = async () => {
    if (searchText) {
      const r = await axApiContext(uc, 'GET', `/galleryitems/search?search=${searchText}`);
      setResults(r);
    }
  }

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZoneName: "short"
  };
  
  return (
    <>
      <Grid container direction="column" spacing={1} margin={0}>
        <Grid item>
          <Grid container sx={{width: '100%', height: '50px'}} direction="row" justifyContent="center" alignItems="center" width="100%" height="100%">
            <Grid item xs={12} md={6}>
              <TextField 
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                onKeyDown={e => {if (e.keyCode === 13) search();}} 
                hiddenlabel="true"
                variant="outlined"
                fullWidth
                inputProps={{ autoFocus: true }}   
                inputRef={searchTextRef}          
            />        
            </Grid>
            <Grid item xs={12} md={2}>
              <Button 
                variant="contained" 
                fullWidth 
                onClick={search}>
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item paddingTop={5} sx={{minHeight: '650px'}}>
          <Container sx={{ py: 4 }} maxWidth="md">
            <Grid container spacing={6}>
              {results && results.length == 0 &&
                <Box sx={{typography: 'body1', fontWeight: 'bold'}}>
                  Oops, no results!  Try again...
                </Box>
              }
              {results && results.length > 0 && results.map((result) => (
                <Grid item key={result.uuid} xs={12} md={4}>
                  <Card
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        // 16:9
                        //https://source.unsplash.com/random
                        //                    alt="random"
                      }}
                      image={result["url"]}
                      style={{backgroundColor: 'white'}}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography>
                        by: {result.username}
                      </Typography>
                      <Typography sx={{fontSize: 10}}>
                        name: {result.filename}
                      </Typography>
                      <Typography sx={{fontSize: 10}}>
                        date: {(new Date(result.created_at)).toLocaleString(options)}
                      </Typography>
                      <Typography sx={{fontSize: 10}}>
                        {result.prompt && `prompt: ${result.prompt}`}
                      </Typography>
                      <Typography>
                        {result?.metadata?.seed && `seed: ${result?.metadata?.seed}`}
                      </Typography>
                      <Typography>
                        {result?.metadata?.model && `model: ${result?.metadata?.model}`}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  );
}