import { useState, useEffect } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import StateCombo from "components/ElementState";


export default function CheckoutShipping({shipments, shipmentUuid, setShipmentUuid}) {

  useEffect(() => {
  }, []);

  const updateShipping = (uuid) => {
    setShipmentUuid(uuid);
  }

  return (
    <form>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Shipping Options</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              value={shipmentUuid}
              label="Shipping Options"
              onChange={(e) => updateShipping(e.target.value)}
            >
              {shipments !== null && shipments.length > 0 &&
                  shipments.map((item) => (
                    <MenuItem value={item.uuid} key={item.uuid}>{item.kind + " - " + item.shipping}</MenuItem>
                  ))
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}
