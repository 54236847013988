import { Typography, Button, Checkbox, FormGroup, FormControlLabel, Snackbar } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useEffect, useState }  from 'react';

import TextField from '@mui/material/TextField';

import { axApiContext, UserContext } from 'UserContext';

export default function Profile() {

  const [snackOpen, setSnackOpen] = useState(false);
  const [message, setMessage] = useState("")
  const [username, setUsername] = useState("")
  const [usernameOrig, setUsernameOrig] = useState("")
  const uc = useContext(UserContext);

  useEffect(() => {
    getUsername();
  }, []);

  const getUsername = async () => {
    const r = await axApiContext(uc, 'GET', '/user/username');
    if (r?.hasUsername === true) {
      setUsername(r.username);
      setUsernameOrig(r.username);
    }
  }

  const handleChangeUsername = async () => {
    try {
      const r = await axApiContext(uc, 'PUT', '/user/username', {"username": username});
      setMessage("Username updated")
      setSnackOpen(true);
    } catch (e) {
      setMessage("Username taken, please try a different one");
      setSnackOpen(true);
    }
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item marginBottom={3} marginLeft={2}>
          <Typography variant="h4">
            Profile    
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={2} margin={1}>
            <Grid item>
              <TextField
                  id="username"
                  label="Username"
                  style = {{width: 260}} 
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  />
            </Grid>
            <Grid item>
              <Button autoFocus onClick={handleChangeUsername} disabled={username === ""} variant="outlined">
                Change Username
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            open={snackOpen}
            autoHideDuration={4000}
            onClose={() => {setSnackOpen(false)}}
            message={message}
          />
      </Grid>
    </>
  ); 
}
