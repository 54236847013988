import { Box } from '@mui/material';

import placeholderImage from 'assets/default-placeholder.png';


// ProductImagePreview shows a preview image of a product with cropped image inside
export function ProductImagePreview({galleryImageUrl, galleryImageSize, previewRect, productImageUrl, cropRect, displayWidth}) {
  const previewImageMaxWidth = 1000;
  const ratio = displayWidth / previewImageMaxWidth;

  console.log("galleryImageSize", galleryImageSize)
  console.log("previewRect", previewRect)
  console.log("cropRect", cropRect)

  return <Box
    sx={{
      width: displayWidth,
      maxWidth: displayWidth   
    }}
    position="relative" >

    <Box
      sx={{
        width: previewRect.w * ratio,
        height: previewRect.h * ratio,
        overflow: "hidden",
        position: "absolute",
        left: `${previewRect.x * ratio}px`,
        top: `${previewRect.y * ratio}px`
      }}
    >
      <Box
      component="img"
      sx={{
        width: previewRect.w / cropRect.w * ratio,
        height: ((galleryImageSize.h / galleryImageSize.w) * previewRect.w) / cropRect.w * ratio,
        position: "absolute",
        left: `${(-1.0 * previewRect.w / cropRect.w) * cropRect.x * ratio}px`,
        top: `${-1.0 * (((galleryImageSize.h / galleryImageSize.w) * previewRect.w) / cropRect.w) * cropRect.y * ratio}px`
      }}
      src={galleryImageUrl || placeholderImage}
      ></Box>
    </Box>

    <Box
      component="img"
      sx={{
        width: displayWidth,
        maxWidth: displayWidth
      }}
      position="relative" left="1px" top="1px"
      src={productImageUrl}
    />
  </Box>;
}

// getDefaultCropRect creates the default fractional crop rect by centering and maximizing rect
export function getDefaultCropRect(previewImageSize, galleryImageSize) {
  const productAR = previewImageSize.h / previewImageSize.w;
  const imageAR = galleryImageSize.h / galleryImageSize.w;

  if (imageAR > productAR) {

    const x1 = 0;
    const w1 = galleryImageSize.w;
    const h1 = productAR * w1;
    const y1 = (galleryImageSize.h - h1) / 2;

    const x2 = 0;
    const w2 = w1 / galleryImageSize.h;
    const y2 = y1 / galleryImageSize.h;
    const h2 = h1 / galleryImageSize.h;

    return {x: x2, y:y2, w: w2, h: h2}

  } else {

    const y1 = 0;
    const h1 = galleryImageSize.h;
    const w1 = h1 / productAR;
    const x1 = (galleryImageSize.w - w1) / 2;

    const x2 = x1 / galleryImageSize.w;
    const w2 = w1 / galleryImageSize.h;
    const y2 = 0;
    const h2 = h1 / galleryImageSize.h;

    return {x: x2, y:y2, w: w2, h: h2}
  }
}  
