//import path from 'path';
const path = require('path-browserify');
import { BottomNavigation, BottomNavigationAction, Box, IconButton, Paper, Popover, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { cloneElement, useCallback, useContext, useEffect, useRef, useState }  from 'react';
import { useSearchParams } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PortraitIcon from '@mui/icons-material/Portrait';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ShopIcon from '@mui/icons-material/Shop';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import ConstructionIcon from '@mui/icons-material/Construction';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonIcon from '@mui/icons-material/Person';
import ImageIcon from '@mui/icons-material/Image';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import CancelIcon from '@mui/icons-material/Cancel';
import { Gallery as Gallery2 } from "react-grid-gallery";  // works well with react-image-lightbox
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import './Gallery.lightgallery.css';

import { isMobile } from 'detect-touch-device';

import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import DialogRender from 'components/DialogRender';
import DialogRenderI2I from 'components/DialogRenderI2I';
import DialogRenderPortrait from 'components/DialogRenderPortrait';
import DialogUpload from 'components/DialogUpload';
import DialogImageInfo from 'components/DialogImageInfo';
import { DialogTerms } from 'components/DialogTerms';
import { axApiContext, getSelectedImages, setSelectedImages, UserContext } from 'UserContext';

// HACK: to get gallery working
var currentGalleryIndex = 0;

const overlayStyle = {
  "backgroundColor": "rgba(0, 0, 0, 0.8)",
  "maxHeight": "240px",
  "overflow": "hidden",
  "position": "absolute",
  "bottom": 0,
  "width": "100%",
  "color": "white",
  "padding": "2px",
  "fontSize": "90%"
}

export default function Gallery() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const refDialogRender = useRef(null);
  const refDialogRenderI2I = useRef(null);
  const refDialogRenderPortrait = useRef(null);
  const refDialogUpload = useRef(null);
  const refDialogImageInfo = useRef(null);
  const lightGallery = useRef(null);
  const [showLightbox, setShowLightbox] = useState(false);
  const [images, setImages] = useState([])
  const [lightboxImages, setLightboxImages] = useState([])
  const [imageUuids, setImageUuids] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [useI2I, setUseI2I] = useState(true);
  const uc = useContext(UserContext);
  const isLargeScreen = useMediaQuery('(min-width:500px)');
  const iconSize = isLargeScreen ? 'medium' : "small";
  const refTerms = useRef(null);
  const [showMenu, setShowMenu] = useState(0);

  useEffect(() => {
    getImagesAndShowRenderIfNone();

    if (searchParams.get('i2i')) {
      setUseI2I(true);
    }
  }, []);

  const getImagesAndShowRenderIfNone = async () => {
    const _images = await getImages();
    if (_images.length == 0) {
      handleRender();
    }
  }

  const get_thumb_path = (url) => {
    if (url.length > 0) {
      if (url.substring(0, 1) === '/') {
        const basename = path.basename(url)
        const dir = path.dirname(url)
        const dot = basename.lastIndexOf(".")
        if (dot > 0) {
          const baseNoExt = basename.slice(0, dot)
          const newBasename = `${baseNoExt}_a2.jpg`
          return `${dir}/${newBasename}`
        } else {
          return url;
        }
      } else {
        const url2 = new URL(url);
        const basename = path.basename(url2.pathname)
        const tokens = basename.split(".")
    
        var newBasename;
        if (tokens.length == 2) {
          newBasename = `${tokens[0]}_a2.jpg}`
        } else {
          return url;
        }
        url2.basename = newBasename;
    
        return url2.href;
      }
    } else {
      return url;
    }
  }

  const get_thumb_path_conditional = (url, kind) => {
    if (kind === "uimage") {
      return get_thumb_path(url)
    } else {
      return url;
    }
  }

  const getImages = async () => {
    const r = await axApiContext(uc, 'GET', '/galleryitems');
    const _images = [];
    const _lightboxImages = [];
    const _uuids = [];
    var selectedUuids = await getSelectedImages();
    var _selectedImage = null;
    var lastImage = null;

    for (const image of r) {
      if (!_selectedImage && selectedUuids.includes(image.uuid)) {
        _selectedImage = {url: image.url, width: image.metadata?.width || 512, height: image.metadata?.height || 512, uuid: image.uuid}
      }
      lastImage = image;
    }
    if (lastImage && (selectedUuids === undefined || selectedUuids.length == 0)) {
      selectedUuids = [lastImage.uuid];
      setSelectedImages(selectedUuids);
      _selectedImage = {url: lastImage.url, width: lastImage.metadata?.width || 512, height: lastImage.metadata?.height || 512, uuid: lastImage.uuid}
    }

    for (const image of r) {
      _images.push({
        src: get_thumb_path_conditional(image.url, image.kind), 
        thumb: get_thumb_path_conditional(image.url, image.kind), 
        width: image.metadata?.width || 512, 
        height: image.metadata?.height || 512, 
        isSelected: selectedUuids.includes(image.uuid),
        subHtml: `<h4>${(image.metadata?.prompt) ? image.metadata?.prompt.substring(0, 23) : ""}</h4>`,
        defaultContainerWidth: 10,
        customOverlay: (
          <div style={overlayStyle}>
            <div>{(image.metadata?.prompt) ? image.metadata?.prompt.substring(0, 23) : image?.original_name}</div>
          </div>
        )
      });
      _lightboxImages.push({
        src: image.url, 
        thumb: get_thumb_path_conditional(image.url, image.kind), 
        subHtml: `<h4>${(image.metadata?.prompt) ? image.metadata?.prompt.substring(0, 23) : ""}</h4>`,
      });
      _uuids.push(image.uuid);
    }
    setImages(_images);
    setImageUuids(_uuids);
    setSelectedImage(_selectedImage);
    setLightboxImages(_lightboxImages);

    return _images;
  }

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
      lightGallery.current?.openGallery(currentGalleryIndex);
    }
  }, []);

  const renderCloseCb = () => {
    getImages();
  }

  const uploadCloseCb = () => {
    getImages();
  }

  const handleSelect = (index) => {
    currentGalleryIndex = index;
    const _images = images.map((item, i) => {
      return (i == index) ? { ...item, isSelected: !item.isSelected } : { ...item, isSelected: false };
    })
    var _selectedImageUuids = [];
    for (const i in images) {
      if (_images[i].isSelected) {
        _selectedImageUuids.push(imageUuids[i]);
      }
    }
    setShowLightbox(false); // HACK: make sure doesn't show lightbox
    setSelectedImages(_selectedImageUuids);
    setImages(_images);

    var _selectedImage = null;
    for (const i in imageUuids) {
      const imageUuid = imageUuids[i]
      const image = images[i]
      if (_selectedImageUuids.includes(imageUuid)) {
        _selectedImage = {url: image.src, width: image?.width || 512, height: image?.height || 512, uuid: imageUuid}
        break;
      }
    }
    setSelectedImage(_selectedImage);
  }

  const handleProducts = () => {
    ReactGA.event({category: "click", action: "gallery-buy"})
    let s = []
    for (let i=0; i<images.length; i++) {
      if (images[i].isSelected) {
        s.push(imageUuids[i])
      }
    }
    navigate("/products");
  }

  const handleClick = (e) => {
    currentGalleryIndex = e;
    handleSelect(e)
    /* uncomment to allow click and zoom
    setShowLightbox(true);
    currentGalleryIndex = e;
    lightGallery?.current?.openGallery(e);
    */
  }

  const handleRender = () => {
    refDialogRender.current.open()
  }

  const handleRenderI2I = () => {
    refDialogRenderI2I.current.open()
  }

  const handleRenderPortrait = () => {
    refDialogRenderPortrait.current.open()
  }

  const handleRemove = async () => {
    if (getSelectedImages().length > 0) {
      for (const imageUuid of getSelectedImages()) {
        await axApiContext(uc, 'DELETE', `/galleryitem/${imageUuid}`);
      }
      getImages();
    }
  }

  const handleDownload = async () => {
    const link = document.createElement('a');
    link.href = selectedImage.url;
    link.click();
  }

  const handleUpload = () => {
    refDialogUpload.current.open(currentGalleryIndex);
  }

  const handleLbClose = () => {
    setShowLightbox(false);
  }

  const handleLbOpen = () => {
    setShowLightbox(true);
    lightGallery?.current?.openGallery();
  }

  const handleInfo = () => {
    refDialogImageInfo.current.open();
  }

  const ToolbarBtn = (props) => {
    const {icon, text, tooltip, onClickHandler, enabled, size} = props;
    const icon2 = cloneElement(icon, {fontSize: size});
    return (
      <Grid container direction="column" alignItems="center" spacing={0} >
        <Grid marginBottom={-0.25}>
          <IconButton onClick={onClickHandler} sx={{borderRadius: 2}} disabled={!enabled}>
            <Tooltip title={tooltip}>
              {icon2}
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid>
          <Typography fontSize="small">{text}</Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Grid container direction="column">
        { false && // desktop menu
        <Grid item>
          <Grid container direction="row" spacing={1} margin={1} alignItems="center" justifyContent="spaceBetween">
            <Grid item>
              <ToolbarBtn icon={<DescriptionIcon/>} text="Create from Text" tooltip="Create image from text" onClickHandler={handleRender} enabled={true}/>
            </Grid>
            <Grid item>
              <ToolbarBtn icon={<ImageIcon/>} text="Create from Image" tooltip="Create image from image" onClickHandler={handleRenderI2I} enabled={selectedImage!=null}/>
            </Grid>
            <Grid item>
              <ToolbarBtn icon={<PortraitIcon/>} text="Create Portrait" tooltip="Create portrait" onClickHandler={handleRenderPortrait} enabled={selectedImage!=null}/>
            </Grid>
            <Grid item>
              <ToolbarBtn icon={<ShopIcon/>} text="Shop" tooltip="Shop" onClickHandler={handleProducts} enabled={selectedImage!=null}/>
            </Grid>
            <Grid item>
              <ToolbarBtn icon={<FileUploadIcon/>} text="Upload" tooltip="Upload" onClickHandler={handleUpload} enabled={true}/>
            </Grid>
            <Grid item>
              <ToolbarBtn icon={<DownloadIcon/>} text="Download" tooltip="Download" onClickHandler={handleDownload} enabled={selectedImage!=null}/>
            </Grid>
            <Grid item>
              <ToolbarBtn icon={<ZoomInIcon/>} text="Slide Show" tooltip="Slide Show" onClickHandler={handleLbOpen} enabled={selectedImage!=null}/>
            </Grid>
            <Grid item>
              <ToolbarBtn icon={<DeleteIcon/>} text="Delete Image" tooltip="Delete Image" onClickHandler={handleRemove} enabled={selectedImage!=null}/>
            </Grid>
            <Grid item>
              <ToolbarBtn icon={<InfoIcon/>} text="Information" tooltip="Information" onClickHandler={handleInfo} enabled={selectedImage!=null}/>
            </Grid>
          </Grid>
        </Grid>
        }
        <Grid item xs={12} md={12} >
          { images.length
            ? <Gallery2 margin={4} images={images} onSelect={handleSelect} onClick={(e) => handleClick(e)}/>
            : <Grid container direction='row'>
                <Grid sx={{mr: .5}}>
                  <Typography>Your gallery of images is empty, click</Typography>
                </Grid>
                <Grid>
                  <AddCircleIcon fontSize='small' />
                </Grid>
                <Grid sx={{ml: .5}}>
                  <Typography>to create an image</Typography>
                </Grid>
              </Grid>
          }
        </Grid>
        { showLightbox ?
        <Grid item>
          <LightGallery 
            licenseKey='6000-0000-000-0001' 
            plugins={[lgThumbnail, lgZoom]} 
            dynamic={true} 
            onInit={onInit}
            onAfterClose={handleLbClose}
            dynamicEl= {lightboxImages}
            mobileSettings={{ controls: false, showCloseIcon: true, download: false }}
          />
        </Grid> : "" }
      </Grid>
      { true && 
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        {showMenu==0 &&
        <Grid container direction="row" alignItems="center" justifyContent="space-evenly" marginBottom={1}>
          <Grid item>
            <ToolbarBtn icon={<AddCircleIcon/>} text="Create" tooltip="" onClickHandler={() => setShowMenu(1)} enabled={true} size={iconSize}/>
          </Grid>
          <Grid item>
            <ToolbarBtn icon={<ShopIcon/>} text="Shop" tooltip="" onClickHandler={handleProducts} enabled={true} size={iconSize}/>
          </Grid>
          <Grid item>
            <ToolbarBtn icon={<PortraitIcon/>} text="Photo" tooltip="" onClickHandler={() => setShowMenu(2)} enabled={true} size={iconSize}/>
          </Grid>
        </Grid>
        } 
        {showMenu==1 &&
        <Grid container direction="row" alignItems="center" justifyContent="space-evenly" marginBottom={1}>
          <Grid item>
            <ToolbarBtn icon={<DescriptionIcon/>} text="Image Gen" tooltip="" onClickHandler={handleRender} enabled={true} size={iconSize}/>
          </Grid>
          <Grid item>
            <ToolbarBtn icon={<ImageIcon/>} text="Image-to-image" tooltip="" onClickHandler={handleRenderI2I} enabled={selectedImage!=null} size={iconSize}/>
          </Grid>
          <Grid item>
            <ToolbarBtn icon={<PortraitIcon/>} text="Portraits" tooltip="" onClickHandler={handleRenderPortrait} enabled={selectedImage!=null} size={iconSize}/>
          </Grid>
          <Grid item>
            <ToolbarBtn icon={<CancelIcon/>} text="Cancel" tooltip="" onClickHandler={() => setShowMenu(0)} enabled={true} size={iconSize}/>
          </Grid>
        </Grid>
        } 
        {showMenu==2 &&
        <Grid container direction="row" alignItems="center" justifyContent="space-evenly" marginBottom={1}>
          <Grid item>
            <ToolbarBtn icon={<FileUploadIcon/>} text="Upload" tooltip="" onClickHandler={handleUpload} enabled={true} size={iconSize}/>
          </Grid>
          <Grid item>
            <ToolbarBtn icon={<DownloadIcon/>} text="Download" tooltip="" onClickHandler={handleDownload} enabled={selectedImage!=null} size={iconSize}/>
          </Grid>
          <Grid item>
            <ToolbarBtn icon={<ZoomInIcon/>} text="Zoom" tooltip="" onClickHandler={handleLbOpen} enabled={selectedImage!=null} size={iconSize}/>
          </Grid>
          <Grid item>
            <ToolbarBtn icon={<DeleteIcon/>} text="Delete" tooltip="" onClickHandler={handleRemove} enabled={selectedImage!=null} size={iconSize}/>
          </Grid>
          <Grid item>
            <ToolbarBtn icon={<InfoIcon/>} text="Info" tooltip="" onClickHandler={handleInfo} enabled={selectedImage!=null} size={iconSize}/>
          </Grid>
          <Grid item>
          <  ToolbarBtn icon={<CancelIcon/>} text="Cancel" tooltip="" onClickHandler={() => setShowMenu(0)} enabled={true} size={iconSize}/>
          </Grid>
        </Grid>
        } 
        </Paper>
      }
      <DialogRender ref={refDialogRender} closeCb={renderCloseCb}></DialogRender>
      <DialogRenderI2I ref={refDialogRenderI2I} closeCb={renderCloseCb} firstImage={selectedImage}></DialogRenderI2I>
      <DialogRenderPortrait ref={refDialogRenderPortrait} closeCb={renderCloseCb} firstImage={selectedImage}></DialogRenderPortrait>
      <DialogUpload ref={refDialogUpload} closeCb={uploadCloseCb}></DialogUpload>
      <DialogImageInfo ref={refDialogImageInfo} closeCb={renderCloseCb} image={selectedImage}></DialogImageInfo>
      <DialogTerms ref={refTerms}></DialogTerms>
    </>);
}