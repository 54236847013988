import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Tooltip, Typography } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState }  from 'react';
import { useSearchParams } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';

import RenderImage from 'components/RenderImage.js';
import RenderImage4 from 'components/RenderImage4.js';
import { DialogSignup } from './DialogSignup';


// eslint-disable-next-line
export default DialogRender = forwardRef((props, ref) => {
  const renderRef = useRef(null);
  const boxRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [useMulti, setUseMulti] = useState(true);
  const [openRender, setOpenRender] = useState(false);
  const [textWidth, setTextWidth] = useState(null);
  const signupEl = useRef(null);

  const mode = props.mode || "multi";
  const prompt = props.prompt || "";
  const showSignup = props.showSignup;
  const showPrompts = props.showPrompts === false ? false : true;

  useImperativeHandle(ref, () => ({
    open() {
      handleOpenRender();
    }
  }));

  useEffect(() => {
    if (searchParams.get('single')) {
      setUseMulti(false);
    }
  }, []);

  useEffect(() => {
    if (boxRef.current) {
      setTextWidth(boxRef.current.clientWidth);
    }
  }, [boxRef.current]);

  const handleOpenRender = () => {
    setOpenRender(true);
  };

  const handleCloseRender = async () => {
    setOpenRender(false);
    await renderRef.current.handleClose();
    if (props?.closeCb) {
      setTimeout(function() {
        props.closeCb();
      }, 500);
    }
  };

  const handleOpenSignup = () => {
    signupEl.current.open();
  }

  const signedInUpUpdateHandler = () => {
  }

  return (
    <>
      <Dialog
        open={openRender}
        onClose={handleCloseRender}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
        fullwidth='true'
        PaperProps={{ sx: {m: 0} }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Image generation"}

          <IconButton
            onClick={() => { window.open("http://help.panoramix.art", '_blank')} }
            sx={{
              position: 'absolute',
              right: 38,
              top: 8,
            }}>
            <Tooltip title="Help">
              <HelpIcon></HelpIcon>
            </Tooltip>
          </IconButton>

          <IconButton
            aria-label="close"
            onClick={handleCloseRender}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>


        </DialogTitle>
        <DialogContent >
          <Box ref={boxRef}>
          { mode=="multi" && <RenderImage4 visible={openRender} isFlex={true} ref={renderRef} autoSave={false} prompt={prompt} showPrompts={showPrompts}/> }
          { mode=="single" && <RenderImage visible={openRender} isFlex={true} ref={renderRef} autoSave={false} prompt={prompt} showPrompts={showPrompts}/> }
          </Box>

          { showSignup && textWidth && 
          <Grid container direction="column" style={{ width: textWidth }} marginTop={1} alignItems="center" spacing={1}>
            <Grid >
              <Typography variant="h6" align="center">
                Sign up to unlock the full functionality of Panoramix, including Image Generation, Image-to-image, Portraits, Gallery and Lightbox.
              </Typography>
            </Grid>
            <Grid marginTop={1}>
              <Button sx={{borderRadius: 8}} color="text" variant="outlined" onClick={handleOpenSignup}>Get Started</Button> 
            </Grid>
          </Grid>
          }
        </DialogContent>
      </Dialog>
      <DialogSignup ref={signupEl} signedInUpUpdateHandler={signedInUpUpdateHandler}></DialogSignup>
    </>
  );
});

function DialogRender(props) {
}