import { Box, Checkbox, FormGroup, FormControlLabel, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { forwardRef, useContext, useImperativeHandle, useEffect, useRef, useState }  from 'react';

import { DialogGeneric } from './DialogGeneric';
import { axApiContext, UserContext } from 'UserContext';
import { LocalConvenienceStoreOutlined } from '@mui/icons-material';

// eslint-disable-next-line
export default DialogImageInfo = forwardRef((props, ref) => {
  const uc = useContext(UserContext);
  const genericEl = useRef(null);
  const [imageInfo, setImageInfo] = useState({});
  const [shareImage, setShareImage] = useState(false);
  const [sharePrompt, setSharePrompt] = useState(false);
  const [shareMetadata, setShareMetadata] = useState(false);

  useImperativeHandle(ref, () => ({
    open() {
      genericEl.current.open();
      getImageInfo();
    }
  }));

  useEffect(() => {
  }, []);

  const getImageInfo = async () => {
    const r = await axApiContext(uc, 'GET', '/galleryitem/' + props.image?.uuid);
    setImageInfo(r)
    setShareImage(r.share_public_image)
    setSharePrompt(r.share_public_prompt)
    setShareMetadata(r.share_public_metadata)

  }

  const handleClose = () => {
    axApiContext(uc, 'PUT', '/galleryitem/' + props.image?.uuid, {
      share_public_image: shareImage,
      share_public_prompt: sharePrompt,
      share_public_metadata: shareMetadata
    });
  };

  return (
    <DialogGeneric ref={genericEl} title="Image Information" handleClose={handleClose}>
      <Grid container direction="column">
        <Table sx={{padding:1, margin:0}}>
          <TableBody>
            <TableRow>
              <TableCell sx={{padding:1, textAlign: "right"}}>
                <Typography>
                  Name:
                </Typography>
              </TableCell>
              <TableCell sx={{padding:1}}>
                <Typography>
                  {imageInfo.original_name}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{padding:1, textAlign: "right"}}>
                <Typography>
                  Kind:
                </Typography>
              </TableCell>
              <TableCell sx={{padding:1}}>
                <Typography>
                  {imageInfo.kind == "uimage" ? "Uploaded Image" : "Created Image"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{padding:1, textAlign: "right"}}>
                <Typography>
                  Date:
                </Typography>
              </TableCell>
              <TableCell sx={{padding:1}}>
                <Typography>
                  {imageInfo.created_at}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{padding:1, textAlign: "right"}}>
                <Typography>
                  Size (pixels):
                </Typography>
              </TableCell>
              <TableCell sx={{padding:1}}>
                <Typography>
                  {`${imageInfo?.metadata?.width} x ${imageInfo?.metadata?.height}`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{padding:1, textAlign: "right"}}>
                <Typography>
                  Prompt:
                </Typography>
              </TableCell>
              <TableCell sx={{padding:1}}>
                <Typography>
                  {imageInfo?.metadata?.prompt}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{padding:1, textAlign: "right"}}>
                <Typography>
                  Metadata Seed:
                </Typography>
              </TableCell>
              <TableCell sx={{padding:1}}>
                <Typography>
                {imageInfo?.metadata?.seed}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{padding:1, textAlign: "right"}}>
                <Typography>
                  Metadata Model:
                </Typography>
              </TableCell>
              <TableCell sx={{padding:1}}>
                <Typography>
                {imageInfo?.metadata?.model}
                </Typography>
              </TableCell>
            </TableRow>            
            <TableRow>
              <TableCell sx={{padding:1, textAlign: "right"}}>
                <Typography>
                  Share Settings:
                </Typography>
              </TableCell>
              <TableCell sx={{padding:1}}>
                <FormGroup>
                  <FormControlLabel 
                    control={
                      <Checkbox checked={shareImage} onChange={(e)=>setShareImage(e.target.checked)}/>
                    }
                    label="Share Image"
                  />
                  <FormControlLabel 
                    control={
                      <Checkbox checked={sharePrompt} onChange={(e)=>setSharePrompt(e.target.checked)}/>
                    }
                    label="Share Prompt" 
                  />
                  <FormControlLabel 
                    control={
                      <Checkbox checked={shareMetadata} onChange={(e)=>setShareMetadata(e.target.checked)}/>
                    }
                    label="Share Metadata" 
                  />
                </FormGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>      
      </Grid>
    </DialogGeneric>
  );
});

function DialogImageInfo(props) {
}