import Paper from "@mui/material/Paper";
import CustomThemeProvider from 'theme.js'
import { UserContextProvider } from 'UserContext';
import Routess from 'Routes';

export default function App() {

  return (
    <UserContextProvider>
      <CustomThemeProvider>
        <Paper>
          <Routess></Routess>
        </Paper>
      </CustomThemeProvider>
    </UserContextProvider>
    );
}