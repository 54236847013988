import { Box, Button, Card, CardContent, CardMedia, Paper, TextField, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga4';

import { axPub, axApi } from 'utils/api';
import { CustomThemeContext, darkPalette, lightPalette } from 'theme';
import { DialogSignin } from 'components/DialogSignin';
import { DialogSignup } from 'components/DialogSignup';
import { DialogAboutUs } from 'components/DialogAboutUs';
import { DialogHowItWorks } from 'components/DialogHowItWorks';
import DialogRender from 'components/DialogRender';

export default function HomeNoUser2() {
  const signinEl = useRef(null);
  const signupEl = useRef(null);
  const aboutUsEl = useRef(null);
  const howItWorksEl = useRef(null);
  const refDialogRender = useRef(null);
  const [sliderImages, setSliderImages] = useState([]);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [popularImages, setPopularImages] = useState([]);
  const [products, setProducts] = useState([]);
  const [prompt, setPrompt] = useState("");
  const isLargeScreen = useMediaQuery('(min-width:1000px)');
  const [searchParams, setSearchParams] = useSearchParams();
  const [mode, setMode] = useState(searchParams.get('m') || 'portrait');
  const [landing, setLanding] = useState(searchParams.get('l') || '');
  const [modes, setModes] = useState([]);
  const { currentTheme } = useContext(CustomThemeContext);
  const [palette, setPalette] = useState(currentTheme == "dark" ? darkPalette : lightPalette);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.searc,
      title: landing});

    axPub("./wwwSliderImages.v3.yaml", "yaml").then((data) => {
      setModes(Object.keys(data.modes));
      const m = data.modes[mode];
      setSliderImages(m);
      let interval = null;
      if (!interval) {
        interval = setInterval(() => {
          setSliderIndex(p => (p + 1) % m.length)
        }, 4000);
      }
      return () => clearInterval(interval);
    });
    axPub("./popularImages.yaml", "yaml").then((data) => {
      setPopularImages(data.modes[mode]);
    });
    axApi("GET", "./products", "", {grouped: "1"}).then(data => setProducts(data));
  }, []);

  const handleRender = () => {
    refDialogRender.current.open()
  }

  const renderCloseCb = () => {
  }

  const handleHowItWorks = () => {
    howItWorksEl.current.open();
  }

  const handleAboutUs = () => {
    aboutUsEl.current.open();
  }

  const handleOpenSignin = () => {
    signinEl.current.open();
  }

  const handleOpenSignup = () => {
    signupEl.current.open();
  }

  const signedInUpUpdateHandler = () => {
  }

  return (
    <>
      <Grid container direction="column">
        { isLargeScreen &&
        <Grid item>
          <Grid container padding={0} direction="row" justifyContent={"space-between"} style={{ color: palette.cover.text, backgroundColor: palette.cover.background, borderBottom: '1px solid black' }}>
            <Grid item marginLeft={1.8} sx={{padding: 1.7}}>
              <Typography variant="logo">
                Panoramix
              </Typography>
            </Grid>
            <Grid item sx={{padding: 2}}>
              <Grid container direction="row" spacing={3}>
                <Grid item marginTop={0.5}>
                  <Button color="text" variant="text" onClick={handleHowItWorks}>How It Works</Button>
                </Grid>
                <Grid item marginTop={0.5}>
                  <Button color="text" variant="text" onClick={handleAboutUs}>About Us</Button>
                </Grid>
                <Grid item marginTop={0.5}>
                  <Button color="text" variant="text" onClick={handleOpenSignin}>Sign In</Button>
                </Grid>
                <Grid item marginTop={0.3}>
                  <Button color="text" variant="outlined" onClick={handleOpenSignup} sx={{borderRadius: 10}}>Get Started</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        }
        { !isLargeScreen &&
        <Grid item>
          <Grid container paddingTop={1.5} paddingLeft={2} paddingBottom={1.5} direction="row" style={{ color: palette.cover.text, backgroundColor: palette.cover.background, borderBottom: '1px solid black' }}>
            <Grid item flex={6}>
              <Typography variant="logo">
                Panoramix
              </Typography>
            </Grid>
            <Grid item flex={6} marginTop={0.6}>
              <Grid container direction="row" justifyContent="flex-end">
                <Grid item>
                  <Button color="text" variant="text" onClick={handleOpenSignin}>Sign In</Button>
                </Grid>
                <Grid item>
                  <Button color="text" variant="text" onClick={handleOpenSignup}>Sign Up</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        }
        { isLargeScreen &&
        <Grid item>
          <Grid container padding={2} direction="row" justifyContent={"space-between"} paddingTop={3} style={{ color: palette.cover.text, backgroundColor: palette.cover.background}} >
            <Grid item flex={6.5}>
              <Grid container direction="column" justifyContent="center" alignContent="center" sx={{height: '100%'}} marginTop={0} spacing={3} wrap="nowrap" >
                <Grid item>
                  <Typography variant="cover">
                    Create, Share, and Print AI Images
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5">
                    Starting with text or photos, make fun and memorable images and print gift items
                  </Typography>
                </Grid>
                <Grid item sx={{width: '95%'}}>
                  <TextField
                    label="Describe your image"
                    placeholder="for example: cat wearing a pink hat"
                    fullWidth
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      handleRender();
                      ev.preventDefault();
                    }
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button disabled={!prompt} color="text" sx={{borderRadius: 10}} variant="outlined" size="large" onClick={handleRender}>Make Image</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item flex={4.5}>
              <Paper sx={{padding: 1, bgcolor: "background.default"}}>
                <Box
                  component="img"
                  sx={{
                    height: { xs: '100%', md: '100%' },
                    width: { xs: '100%', md: '100%' },
                  }}
                  src={sliderImages.length > 0 ? sliderImages[sliderIndex].imageUrl : ""}
                />
                <Box sx={{ height: '75px' }}>
                  <Typography>
                    {sliderImages.length > 0 ? sliderImages[sliderIndex].description : ""}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        }
        { !isLargeScreen &&
        <Grid item>
          <Grid container direction="column" paddingLeft={2} paddingBottom={5} style={{ color: palette.text.main, backgroundColor: palette.cover }}>
            <Grid item paddingTop={5} paddingBottom={2}>
              <Typography variant="cover">
                Create, Share, and Print AI Images
              </Typography>
            </Grid>
            <Grid item paddingBottom={2}>
              <Typography variant="h6">
                Starting with text or photos, make fun and memorable images and print gift items
              </Typography>
            </Grid>
            <Grid item marginRight={1} paddingBottom={2}>
              <TextField
                label="Describe your image"
                placeholder="for example: cat wearing a pink hat"
                fullWidth
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    handleRender();
                    ev.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Button disabled={!prompt} color="text" sx={{borderRadius: 10}} variant="outlined" size="large" onClick={handleRender}>Make Image</Button>
            </Grid>
          </Grid>
        </Grid>
        }
        { !isLargeScreen &&
        <Grid item>
          <Grid container direction="column" justifyContent={"center"} >
            <Grid item >
              <Box
                component="img"
                sx={{
                  height: { xs: '100%', md: '100%' },
                  width: { xs: '100%', md: '100%' },
                }}
                src={sliderImages.length > 0 ? sliderImages[sliderIndex].imageUrl : ""}
              />
              <Box sx={{ height: '75px' }}>
                <Typography>
                  {sliderImages.length > 0 ? sliderImages[sliderIndex].description : ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        }
        <Grid item align={"center"} sx={{width: '100%', marginTop: 1, padding: 4}}>
          <Grid container padding={1} direction="column">
            <Grid>
              <Typography gutterBottom variant="h5" component="h2">
                Popular Images
              </Typography>
            </Grid>
            <Grid container direction="row" columnSpacing={10}>
              { popularImages.map((image, i) => (
                <Grid item key={i}>
                  <Grid container direction="column">
                    <Grid item>
                      <Box
                        component="img"
                        sx={{
                          width: 150
                        }}
                        src={image.imageUrl}
                        alt='popular image'
                      />
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom>
                        by {image.by}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item align={"center"} sx={{width: '100%', padding: 4}}>
          <Grid container padding={1} direction="column">
            <Grid>
              <Typography gutterBottom variant="h5" component="h2">
                Print your art!
              </Typography>
            </Grid>
            <Grid container direction="row" spacing={4} margin="3">
              {products.map((product) => (
                <Grid item key={product.group_key} xs={12} sm={4} md={4}>
                  <Card
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        // 16:9
                        //https://source.unsplash.com/random
                        //                    alt="random"
                      }}
                      image={product.showcase_image_url}
                      style={{backgroundColor: 'white'}}
                      alt={product.alt_text}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {product.group_key}
                      </Typography>
                      <Typography>
                        {`starting at $${product.price} for a ${product.name_hr}`}
                      </Typography>
                      <Typography>
                        {product.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogSignin ref={signinEl} signedInUpUpdateHandler={signedInUpUpdateHandler}></DialogSignin>
      <DialogSignup ref={signupEl} signedInUpUpdateHandler={signedInUpUpdateHandler}></DialogSignup>
      <DialogAboutUs ref={aboutUsEl}></DialogAboutUs>
      <DialogHowItWorks ref={howItWorksEl}></DialogHowItWorks>
      <DialogRender ref={refDialogRender} closeCb={renderCloseCb} mode="single" showPrompts={false} prompt={prompt} showSignup={true}></DialogRender>
      <CookieConsent
        location="bottom"
        buttonText="Okay"
        cookieName="consentCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={365}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
    </>
  );
}
