import { useContext, useState, useEffect } from "react";
import { Grid, Typography } from '@mui/material';

import { axApiContext, UserContext } from 'UserContext';

const defaultReceipt = {
  orderNum: "",
  date: "",
  shippingAddress: {
    firstName: ""
  },
  billingAddress: {
    firstName: ""
  },
  subtotal: "$0.00",
  shipping: "$0.00",
  tax: "$0.00",
  total: "$0.00",
  items: []
}

export default function CheckoutReceipt({uuid}) {

  const [receipt, setReceipt] = useState(defaultReceipt)
  const [receiptText, setReceiptText] = useState("");
  const uc = useContext(UserContext);

  useEffect(() => {
    updateReceipt();
  }, []);

  const updateReceipt = async () => {  
    const _receipt = await axApiContext(uc, 'GET', '/cart/receipt/' + uuid);
    setReceipt(_receipt);

    const date = Date(_receipt.date)
    const discount = _receipt.discount !== "0.00" ? `Discount: $${_receipt.discount}` : ""  

    let _receiptText = 
`Order Number: ${_receipt.orderNum}
Date: ${date.toLocaleString()}

Shipping Address
${_receipt.shippingAddress.firstName} ${_receipt.shippingAddress.lastName}
${_receipt.shippingAddress.address1}${_receipt.shippingAddress.address2 ? _receipt.shippingAddress.address2 + "\n" : ""}
${_receipt.shippingAddress.city}, ${_receipt.shippingAddress.state} ${_receipt.shippingAddress.postalCode}

Billing Address
${_receipt.billingAddress.firstName} ${_receipt.billingAddress.lastName}
${_receipt.billingAddress.address1}${_receipt.billingAddress.address2 ? _receipt.billingAddress.address2 + "\n" : ""}
${_receipt.billingAddress.city}, ${_receipt.billingAddress.state} ${_receipt.billingAddress.postalCode}

Items
`
    for (const item of _receipt.items) {
      _receiptText += `(${item.quantity}) ${item.product.name_hr} $${item.product.price}\n`
    }

    _receiptText += 
`
Subtotal: $${_receipt.subtotal}
${discount}
Shipping: $${_receipt.shipping}
Tax: $${_receipt.tax}
Total: $${_receipt.total}
`

    setReceiptText(_receiptText);
  }
  
  return (
    <Grid container direction="column" spacing={2} margin={1}>
      <Grid item xs={12} sm={4}>
        <Typography variant="h5">
          Receipt
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography style={{whiteSpace: 'pre-line'}}>
          {receiptText}
        </Typography>
      </Grid>
    </Grid>
  );
}
