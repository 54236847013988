import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Typography, Button, Paper, Step, StepLabel, Stepper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import StripeBlurple from "assets/stripe-blurple.svg";

import ReactGA from 'react-ga4';

import CheckoutAddress from '../components/CheckoutAddress'; 
import CheckoutCC from "../components/CheckoutCC";
import CheckoutReceipt from "../components/CheckoutReceipt";

import { axApiContext, UserContext } from 'UserContext';
import CheckoutShipping from "components/CheckoutShipping";


const stepsWithShipping = ['Billing Address', 'Shipping Address', 'Shipping', 'Card Info'];
const stepsWithoutShipping = ['Billing Info', 'Card Info'];

const defaultAddress = {
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  postalCode: ""
}

export default function Checkout() {
  const refPayClick = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [billing, setBilling] = useState(defaultAddress);
  const [billingValid, setBillingValid] = useState(false);
  const [shipping, setShipping] = useState(defaultAddress)
  const [shippingValid, setShippingValid] = useState(false);
  const [shipmentUuid, setShipmentUuid] = useState("")
  const [shipments, setShipments] = useState([])
  const [steps, setSteps] = useState(stepsWithoutShipping);
  const [uuid, setUuid] = useState("");
  const uc = useContext(UserContext);

  useEffect(() => {
    updateCartInfo();
  }, []);

  const updateCartInfo = async () => {
    const c = await axApiContext(uc, 'GET', '/cart');
    if (c.billingAddress) {
      setBilling(c.billingAddress);
    }
    if (c.shippingAddress) {
      setShipping(c.shippingAddress);
    }

    const s = await axApiContext(uc, 'GET', '/cart/shipment');
    setShipments(s)
    setShipmentUuid(c.shippingKindUuid)

    setUuid(c.uuid);
    setSteps(c.shipable ? stepsWithShipping : stepsWithoutShipping)
  }

  const saveBilling = async () => {
    await axApiContext(uc, 'PUT', '/cart/address/billing', billing);
  }

  const saveShipping = async () => {
    await axApiContext(uc, 'PUT', '/cart/address/shipping', shipping);
  }

  const isStepOptional = (step) => {
    return false;
  };

  const handleNext = async () => {

    if (activeStep == 0) {
      saveBilling();
    }

    if (activeStep == 1) {
      saveShipping();
    }

    if (activeStep == 3 && refPayClick.current) {
      if (!refPayClick.current()) {
        return;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    ReactGA.event({category: "click", action: `checkout-step-${activeStep}`})
  };

  const handleBack = () => {
    if (activeStep == 1) {
      saveShipping();
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSetShipmentUuid = async (uuid) => {
    await axApiContext(uc, 'PUT', '/cart/shipment', {"shipment_kind_uuid": uuid});
    setShipmentUuid(uuid)
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item marginBottom={3} marginLeft={2}>
          <Typography variant="h4">
            Checkout
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box sx={{ width: '100%'}}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Box marginTop={4} marginBottom={4}>
              {activeStep == 0 &&
                <CheckoutAddress setIsValid={setBillingValid} address={billing} setAddress={setBilling}></CheckoutAddress>
              }
              {activeStep == 1 &&
                <CheckoutAddress setIsValid={setShippingValid} address={shipping} setAddress={setShipping}></CheckoutAddress>
              }
              {activeStep == 2 && 
                <CheckoutShipping handleBack={handleBack} handleNext={handleNext} shipments={shipments} shipmentUuid={shipmentUuid} setShipmentUuid={handleSetShipmentUuid}></CheckoutShipping>
              }
              {activeStep == 3 && 
                <CheckoutCC handleBack={handleBack} handleNext={handleNext}></CheckoutCC>
              }
              {activeStep == 4 && 
                <CheckoutReceipt uuid={uuid}></CheckoutReceipt>
              }
            </Box>
            {activeStep === steps.length && (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }} variant="h5">
                  Thank you for your Order!
                </Typography>
              </React.Fragment>
            )}
            {activeStep < 3 && (
              <React.Fragment>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    variant="outlined"
                  >
                    Back
                  </Button>
                  <Button 
                    onClick={handleNext} 
                    disabled={(activeStep === 0 && !billingValid) || (activeStep === 1 && !shippingValid)}
                    variant="outlined"
                  >
                    {activeStep === steps.length - 1 ? 'Submit Payment' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
          <Grid container item direction="row" fullwidth="true" justifyContent="center" marginTop={4}>
            <Grid item>
              <Box sx={{width: '140px'}} >
                <img src={StripeBlurple} alt="Stripe" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
