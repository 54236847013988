import { Box, Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material';
import { forwardRef, useContext, useEffect, useImperativeHandle, useState }  from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import ReactGA from 'react-ga4';

import { axPub, Urls } from 'utils/api';
import { axApiContextOptional, UserContext } from 'UserContext';
import { genAnonymousUid } from 'UserContext';
import AppInfo from 'AppInfo.json';

ReactGA.initialize(AppInfo.gtag);


const PromptMenu = ({ title, texts, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedText, setSelectedText] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (text) => {
    setSelectedText(text);
    setAnchorEl(null);
    onSelect(text)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button onClick={handleClick}>{title}</Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {texts.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuItemClick(item.text)}
            style={{whiteSpace: 'normal'}} 
          >
            { item.image &&
              <Box
                component="img"
                sx={{
                  height: { xs: 100, md: 100 },
                }}
                src={item.image}
              />
            }
            {item.text}            
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default RenderImage = forwardRef((props, ref) => {
  const { children, isFlex, autoSave, ...other } = props;
  const [showSpinner, setShowSpinner] = useState(false);
  const [timer, setTimer] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("https://source.unsplash.com/random?auto=format&w=400&dpr=2&r=" + Math.random());
  const [prompt, setPrompt] = useState("")
  const [prompts, setPrompts] = useState([]);
  const [promptValues, setPromptValues] = useState([]);
  const [progress, setProgress] = useState(0);
  const uc = useContext(UserContext);
  const blank = "none";
  const imageWidthSm = screen.width - 50;

  useEffect(() => {  

    axPub("./di1Prompts.yaml", "yaml").then(data => {
      // add blanks to prompts in cast they don't want to select
      let pvs = [];
      const p = data.prompts.freestyle
      for (let i=0; i < p.length; i++) {
        p[i].values.unshift(blank);
        pvs.push("");
      }
      setPrompts(p);
      setPromptValues(pvs);
    });

    if (props.prompt) {
      handleMakeImage();
    }

  }, []);

  useImperativeHandle(ref, () => ({
    handleClose() {
    }
  }));

  const handleChange = (event, index) => {
    setPromptValues(
      promptValues.map((p, i) => {
        return i === index ? (event.target.value === blank ? "" : event.target.value) : p
      })
    )
  };

  const handleMakeImage = async () => {

    ReactGA.event({category: "click", action: "render-1"})
    setProgress(0);
    setShowSpinner(true);
    const startTime = Date.now();
    const maxSecs = 90;
    const expectSecs = 20;
    const intervalSecs = 1;

    const p = props.prompt || prompt;
    const prompt2 = (p + " " + promptValues.join(" ")).trim()

    const userUid = genAnonymousUid();

    // call api to create image supplying metadata
    var timer;
    axApiContextOptional(
      uc,
      "POST",
      Urls.dimage,
      {metadata: {prompt: prompt2}, kind: "single_t2i", user_uid: userUid}).then(responsePost => {

      setShowImage(false);

      timer = setInterval(() => {
        // call api until status is rendered
        axApiContextOptional(
          uc,
          "GET", 
          Urls.dimage + "/" + responsePost?.uuid).then(responseGet => {

          if (responseGet.status == "rendered") {
            setShowSpinner(false);
            clearInterval(timer);
            setImageUrl(responseGet.dimages[0].url);
            setShowImage(true);
          } else {
            setProgress((p) => (p + 100/expectSecs))
            const millis = Date.now() - startTime;
            if (millis > maxSecs * 1000) {
              // times up!
              clearInterval(timer);
              setShowSpinner(false);    
            }
          }
        }).catch(e => {
          clearInterval(timer);
          setShowSpinner(false);
        });

      }, intervalSecs * 1000);
      setTimer(timer)
    });
  }

  return (
    <Box sx={{width: {xs: imageWidthSm, md: props.showPrompts ? 850 : 400}}} paddingTop={1}>
      <Grid container direction="row" spacing={1} wrap="wrap">
        { props.showPrompts !== false &&
        <Grid item md={6}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <TextField
                label="Describe your scene"
                placeholder="Describe your scene"
                multiline
                fullWidth
                rows={4}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    handleMakeImage();
                    ev.preventDefault();
                  }
                }}
              />
            </Grid>  
            <Grid item>
              <Grid container direction="row" spacing={1}>
                {prompts?.map((prompt, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <PromptMenu title={prompt.title} texts={prompt.values} onSelect={handleSelect} />
                  </Grid>
                ))}
              </Grid>  
            </Grid>  
            <Grid item xs={1}>
              <Button variant="contained" onClick={handleMakeImage} sx={{width: 150, bottom: 3}} disabled={!prompt}>Create</Button>
            </Grid>       
          </Grid>
        </Grid>
        }
        <Grid item xs={6} md={6}>
          {showImage ? <Box
            component="img"
            sx={{
              height: { xs: imageWidthSm, md: 400 },
              width: { xs: imageWidthSm, md: 400 },
            }}
            src={imageUrl}
          /> : <Box
          sx={{
            height: { xs: imageWidthSm, md: 400 },
            width: { xs: imageWidthSm, md: 400 },
            border: 1,
            borderColor: 'rgb(150, 150, 150)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px'
          }}
          >
            <Typography>
              Rendering Image...
            </Typography>
          </Box> } 
          {showSpinner ? <LinearProgress sx={{width: {xs: imageWidthSm, md: 400}, height: 8}} variant="determinate" value={progress}/>: ""}
        </Grid>
      </Grid>
    </Box>
  );
});

function RenderImage(props, ref) {
}