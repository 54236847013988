import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography variant="body2" align="center">
      {'Copyright © '}
      <Link href="https://panoramix.art/" color="text.primary">
        Panoramix, LLC
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {

  return (
    <Box component="footer" sx={{padding: 4, mt: 5}}>
      <Copyright />
    </Box>
  );
}