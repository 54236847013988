import { useState, useContext, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { signinWithEmail , UserContext} from 'UserContext.tsx';
import { axApi } from 'utils/api.tsx';
import { DialogForgotPassword } from './DialogForgotPassword';


export default function SigninEmail(props) {

  const forgotEl = useRef(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [message, setMessage] = useState("")
  const uc = useContext(UserContext);

  const handleSignin = async (data) => {
    if (await signinWithEmail(uc, email, password)) {
      props.signedInUpUpdateHandler();
    } else {
      setMessage("Oops, unknown user or password")
      setSnackOpen(true);
    }
  };

  const handleForgotPassword = (data) => {
    forgotEl.current.open();
  };

  const cbForgotPassword = () => {
    forgotEl.current.close();
    setPassword("");
  };

  return (
    <>
      <Grid container direction="column" justifyContent="flex-start" alignItems="left" rowGap={2}>
        <Grid item>
          <TextField
            required
            id="email"
            label="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            style = {{width: 300}} 
          />
        </Grid>
        <Grid item>
          <TextField
            required
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            style = {{width: 300}} 
            onKeyDown={e => {if (e.keyCode === 13) handleSignin();}} 
          />
        </Grid>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button autoFocus onClick={handleSignin} disabled={!email || !password}>
              Sign In
            </Button>
          </Grid>
          <Grid item>
            <Button autoFocus onClick={handleForgotPassword} disabled={false}>
              Forgot password?
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          open={snackOpen}
          autoHideDuration={4000}
          onClose={() => {setSnackOpen(false)}}
          message={message}
        />
      </Grid>   
      <DialogForgotPassword ref={forgotEl} onClose={cbForgotPassword}></DialogForgotPassword>
    </> 
  );
}